import React, { useState } from 'react';
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import { Container, Col, Row } from "react-bootstrap"

function ShoeSizes() {
  // Define shoe sizes for each category
  const shoeSizes = {
    youth: [1, 2, 3, 4, 5, 6],
    mens: [7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 13, 14, 15, 16],
    womens: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 11, 12]
  };

  // State to hold the selected sizes
  const [selectedSizes, setSelectedSizes] = useState({
    youth: [],
    mens: [],
    womens: []
  });

  // State to manage visibility of sizes
  const [visibility, setVisibility] = useState({
    youth: false,
    mens: false,
    womens: false
  });

  // Handle checkbox visibility toggle
  const handleVisibilityChange = (category) => {
    setVisibility({
      ...visibility,
      [category]: !visibility[category]
    });
  };

  // Handle change in checkboxes
  const handleChange = (category, size) => {
    const currentSizes = selectedSizes[category];
    if (currentSizes.includes(size)) {
      setSelectedSizes({
        ...selectedSizes,
        [category]: currentSizes.filter(s => s !== size)
      });
    } else {
      setSelectedSizes({
        ...selectedSizes,
        [category]: [...currentSizes, size]
      });
    }
  };

  // Submit and alert selected sizes
  const handleSubmit = () => {
    const sizesCSV = Object.entries(selectedSizes)
      .map(([category, sizes]) => sizes.map(size => `${category} ${size}`).join(', '))
      .filter(s => s.length > 0)
      .join(', ');
    alert(sizesCSV);
  };

  // Component styling
  const sectionStyle = {
    marginBottom: '20px',
  };

  const checkboxStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginTop: '5px',
  };

  const labelStyle = {
    minWidth: 'calc(25% - 10px)',
    textAlign: 'left',
  };

  return (
    <>
      <SEO title={`Shoe sizes demo`}
          description={`Shoe sizes demo`} />
      <Container>
        <Row>
          <Col xs={12} lg={8} sm={10}>
            <h1>Transport shoe size demo</h1>
            <div>
                {Object.keys(shoeSizes).map((category) => (
                    <div key={category} style={sectionStyle}>
                    <label>
                        <input
                        className="mr-2"
                        type="checkbox"
                        checked={visibility[category]}
                        onChange={() => handleVisibilityChange(category)}
                        />
                        Show {category.charAt(0).toUpperCase() + category.slice(1)} Sizes
                    </label>
                    {visibility[category] && (
                        <div style={checkboxStyle}>
                        {shoeSizes[category].map((size) => (
                            <label key={size} style={labelStyle}>
                            <input
                                className="mr-2"
                                type="checkbox"
                                checked={selectedSizes[category].includes(size)}
                                onChange={() => handleChange(category, size)}
                            />
                            {size}
                            </label>
                        ))}
                        </div>
                    )}
                    </div>
                ))}
                <button onClick={handleSubmit}>Submit</button>
            </div>
          </Col>
        </Row>
    </Container>
    </>
   
  );
}

export default ShoeSizes;

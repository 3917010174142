import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import { Container, Col, Row } from "react-bootstrap";
import Footer from "src/components/Footer"

const ToolsAndGames = () => {
    return (
        <Layout>
            <SEO title={`Tools and Games`}
                 description={``} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Breadcrumb />
                        <h1>Tools and Games</h1>
                        <p>Remember when sites had games? Fuck Instagram this is my games page.</p>
                        <h2 className="border-b border-black border-solid">Tools</h2>
                        <li>
                            <Link to="/what-da-fuck-is-a-cookie">WHAT DA FUCK IS A COOKIE????</Link>
                            <br />
                            <p>This is a proxy server marketed to be used as a tool to get past paywalls</p>
                        </li>
                        <h2 className="border-b border-black border-solid">Games</h2>
                        <li>
                            <Link to="/tools-and-games/ascii-drag">Ascii Drag</Link>
                            <br />
                            <p>
                                This is an interactive digital toy I made. It applies physics logic to 
                                <a href="https://en.wikipedia.org/wiki/Document_Object_Model" target="_blank">DOM</a> elements.
                            </p>
                        </li>

                        
                    </Col>
                </Row>    
            </Container>
            <Footer />
        </Layout>
    );
};

export default ToolsAndGames;
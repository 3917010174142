import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import { Container, Col, Row } from "react-bootstrap";
import axios from 'axios';

const MoodPage = () => {
    const apiUrl = process.env.GATSBY_API_URL;
    const trailingUrl = process.env.NODE_ENV === `production` ? `https://gasfried.com/mothafuckin-api/image/fuck` : `http://localhost:9001/mothafuckin-api/image/fuck`
    const [showImagesClicked, setShowImagesClicked] = useState(false);
    const [images, setImages] = useState([]);
    const [getErrorMessage, setGetErrorMessage] = useState('');
    const [postErrorMessage, setPostErrorMessage] = useState('');
    const [postSuccessMessage, setPostSuccessMessage] = useState('');
    const [threadPosted, setThreadPosted] = useState(false);

    const showImages = async () => {
        try {
            const response = await axios.get(`${apiUrl}image/nRandom/3`);
            setImages(response.data);

            setShowImagesClicked(true);
        } catch (error) {
            console.log({error})
            setGetErrorMessage('Failed to fetch images');
        }
    };

    const threadEntry = async (event) => {
        event.preventDefault();
        const entry = {
            text: event.target.text.value,
            imageOne: images[0].id,
            imageTwo: images[1].id,
            imageThree: images[2].id,
            password: event.target.password.value
        };

        try {
            const response = await axios.post(`${apiUrl}thread`, entry, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setPostSuccessMessage('Entry successfully created!');
                setThreadPosted(true);
                setPostErrorMessage('');
            } else {
                console.log(response.status)
            }
        } catch (error) {
            setPostErrorMessage('Failed to create entry');
            setPostSuccessMessage('');
        }
    };

    return (
        <Layout>
            <SEO title={`Threads`} description={``} />
            <Container>
                <Row>
                    <Col xs={12} className="border-bottom">
                        <Breadcrumb />
                        <h1>Threads</h1>
                    </Col>
                </Row>    
                <Row>
                    <Col xs={12}>
                        <button onClick={showImages}>Show Images</button>
                        {getErrorMessage && <p>{getErrorMessage}</p>}
                        <Row>
                            {images.map((image, index) => (
                                <Col key={index} xs={12} md={4} className="mb-3">
                                    <img src={`${trailingUrl}/${image.hash}.jpg`} alt={`Image ${index + 1}`} style={{ width: '100%', paddingRight: '1em' }} />
                                </Col>
                            ))}
                        </Row>
                        {showImagesClicked && !threadPosted && (
                            <>
                                <form onSubmit={threadEntry}>
                                    <div>
                                        <label>
                                            Text:
                                            <input type="text" name="text" required />
                                        </label>
                                    </div>
                                    <div>
                                        <label>
                                            Password:
                                            <input type="password" name="password" required />
                                        </label>
                                    </div>
                                    <button type="submit">Submit</button>
                                </form>
                                {postErrorMessage && <p>{postErrorMessage}</p>}
                            </>
                        )}
                        {postSuccessMessage && <p>{postSuccessMessage}</p>}
                        <Link href="/threads">See Old Threads</Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default MoodPage;

import React, { useState } from "react";
import axios from "axios";
import ColorOverTime from 'src/components/graphs/ColorOverTime';

const ToggleColorFrequencyGraph = ({ color, hex, uploads }) => {
    const [graphIsVisible, setGraphIsVisible] = useState(false);

    const click = async (e) => {
        e.preventDefault();

        setGraphIsVisible(!graphIsVisible);
    }

    return (
        <div className="mb-2">
            <a href="#" onClick={click}>
                {( graphIsVisible ? `Hide ` : 
                                    `Show `
                ) + 'upload frequency graph for images with color`' + color + '`'}
            </a>
            {graphIsVisible && <ColorOverTime colorName={color} hex={hex} data={uploads} />}
        </div>
    )
}

export default ToggleColorFrequencyGraph;

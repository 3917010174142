import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useLocation, navigate } from "@reach/router";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import { Container, Col, Row } from "react-bootstrap";
import Footer from "src/components/Footer"
import axios from 'axios';
import { graphql } from "gatsby"


const Subscribe = ({ data }) => {
    const apiUrl = process.env.GATSBY_API_URL;
    const [subscribeEmail, setSubscribeEmail] = useState('');
    const [name, setName] = useState('');
    const [subscribeRequestGoing, setSubscribeRequestGoing] = useState(false)
    const [subscribeErrorMessage, setSubscribeErrorMessage] = useState('');
    const [subscribeSuccessText, setSubcribeSuccessText] = useState('');

    const [unsubscribeEmail, setUnsubscribeEmail] = useState('');
    const [unsubscribeRequestGoing, setUnsubscribeRequestGoing] = useState(false);
    const [unsubscribeErrorMessage, setUnsubscribeErrorMessage] = useState('');

    const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
    const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a deveoloper"
    const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''

    const subscribe = async (e) => {
        e.preventDefault();
        setSubscribeErrorMessage('');
        setSubcribeSuccessText('');
        setSubscribeRequestGoing(true)

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if (!emailRegex.test(subscribeEmail)) {
            setSubscribeRequestGoing(false);
            setSubscribeErrorMessage('Please enter a valid email address.');
            return;
        }


        try {
            const response = await axios.post(`${apiUrl}subscribe`, { email: subscribeEmail, name });

            if (response.status === 200) {
                setSubscribeRequestGoing(false)
                setSubcribeSuccessText(`${response.data.newSubscription.email} is now subscribed.`)

            } else {
                setSubscribeRequestGoing(false)
                setSubscribeErrorMessage('Server error whoops.');
            }
        } catch (error) {
            setSubscribeRequestGoing(false)
            console.error("Error:", error.response);
            if(error.response.status === 409) {
                setSubscribeErrorMessage(`${subscribeEmail} is already subscribed.`);
            } else {
                setSubscribeErrorMessage('An error occurred.');

            }
        }
    };

    const unsubscribe = async (e) => {
        e.preventDefault();
        setSubscribeErrorMessage('');
        setUnsubscribeRequestGoing(true)

        setTimeout(() => {
            setUnsubscribeRequestGoing(false)

            setUnsubscribeErrorMessage(`Bitch I'm not an inventor I don't know how to unsubscribe you.`);
        }, 2000);
    };

  
    return (
        <Layout>
            <SEO title={metadataTitle}
                 description={metadataDescription} 
                 imageURL={metadataImg} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Breadcrumb />
                        <p><h1>Subscribe</h1></p>
                        <p>I want to connect with people, especially digitally. This website exists to show my work and use my voice, and this page allows you to subscribe to emails that go out once a month to show what I've been working on and thinking about.</p>
                        <p>Popular tools to create a voice online require you to hoe yourself, and require you to use an oppressor's platform and language.</p>
                        <p>Appearing on an audience's feed requires you to censor yourself and to conform to their standards. You can rage against the platform... but what is the value of being on the platform if the platform doesn't actually amplify your voice? Further, they get your valuable data. Your biometrics, your attention, your consciousness scribed in binary. This is what gives these oppressors their power, which I resist.</p>
                        <p>This site is built with javascript, python, and sql. I have control over this site, the content, and my voice. However this platform doesn't have reach, even among friends. It's tough to compete for attention against tech companies whose large pool of resources go towards reverse engineering your brain in an attempt to capture your attention.</p>
                        <p>In order to increase my reach, I create a succinct archive of the new content of this website each month and email it out to those interested.</p>
                        <p><Link to="/archive">Past archives here</Link></p>
                        <p>This custom mailing list doesn't share data, sell data, or use any third party services.</p>
                        <p>I don't have social media - this is how you follow me.</p>
                        <form onSubmit={subscribe} className="flex flex-col md:flex-row mb-4">
                            <input
                                type="text"
                                value={subscribeEmail}
                                onChange={(e) => setSubscribeEmail(e.target.value)}
                                placeholder="Your email"
                                maxLength="255"
                                className="px-4 py-2 flex-grow border border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Your name (optional)"
                                maxLength="255"
                                className="px-4 py-2 flex-grow border border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <button
                                type="submit"
                                className="px-4 py-2 h-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-0 flex-start flex"
                            >
                                Subscribe
                            </button>
                        </form>
                        {subscribeRequestGoing && <p>Subscribing...</p>}
                        {subscribeErrorMessage && <p style={{ color: 'red' }}>{subscribeErrorMessage}</p>}
                        {subscribeSuccessText && <p style={{ color: 'green' }}>{subscribeSuccessText}</p>}
                        <p>
                            <h4>Unsubscribe</h4>
                        </p>
                        <p>Do you want to not receive these emails anymore? No problem, unsubscribe below:</p>
                        <form onSubmit={unsubscribe} className="flex flex-col md:flex-row mb-4">
                            <input
                                type="text"
                                value={unsubscribeEmail}
                                onChange={(e) => setUnsubscribeEmail(e.target.value)}
                                placeholder="Your email"
                                className="px-4 py-2 flex-grow border border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <button
                                type="submit"
                                className="px-4 py-2 h-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-0"
                            >
                                Unsubscribe
                            </button>
                        </form>
                        {unsubscribeRequestGoing && <p>Unsubscribing...</p>}
                        {unsubscribeErrorMessage && <p style={{ color: 'red' }}>{unsubscribeErrorMessage}</p>}
                    </Col>
                </Row>    
            </Container>
            <Footer />
        </Layout>
    );
};

export default Subscribe;

export const pageQuery = graphql`
  query wpSubscribeQuery {
    wpPage(title: {eq: "SUBSCRIBE"}) {
      metadata {
        metadataTitle
        metadataImage {
          localFile {
            url
          }
        }
        metadataDescription
      }
    }
  }`
import React, { useEffect, useState, useRef } from "react"
import { Container, Col, Row } from "react-bootstrap"

import "src/assets/less/homepage.less"

const Body = (props) => {
  const [rect, setRect] = useState(null)
  const [headerRect, setHeaderRect] = useState(null)

  const descriptionRef = useRef()

  useEffect(() => {
    const links = descriptionRef.current
    const theRect =links.getBoundingClientRect()
    setRect(theRect)
    setHeaderRect(props.headerRect)
    props.setBodyHeight(theRect.height)

  }, [props.headerRect])
  

  return (
    <Container style={rect === null  ? {position: `relative`} : 
                                       { position: `absolute`, 
                                         width: rect.width, 
                                         height: rect.height,
                                         maxHeight: document.documentElement.clientWidth > 768 ? `` : `50vh`,
                                         overflow: document.documentElement.clientWidth > 768 ? `` : `scroll`,
                                         top: headerRect ? headerRect.height + headerRect.top * 2 : 0,
                                        //  left: rect.left - 12 to account for 12 px of padding :)
                                         left: rect.left,
                                         margin: 0,
                                          zIndex: 1 }}
               ref={descriptionRef} 
               className={`homepageContainer`}
    >
      <Row>
        <Col xs={12}>
          <div className="description" dangerouslySetInnerHTML={{__html: props.content}} ></div>
          <div> 
            Drag the path line to change the path of the animation! <button className={`link`} onClick={ () => window.resetAnimation = true }>Restart the pointer animation</button>
          </div>
        </Col>  
      </Row>
    </Container>
  )
}

export default Body
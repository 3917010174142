import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const MoodGraph = ({ data, height }) => {
  const moodData = {
    labels: data.map(entry => entry.node.createdAt),
    datasets: [
      {
        label: 'Mood',
        data: data.map(entry => ({ x: entry.node.createdAt, y: entry.node.mood })),
        fill: true,
        borderColor: 'rgba(0, 0, 0, 0.2)',
        tension: 0.1
      },
    ],
  };

  const options = {
    animation: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
        },
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Mood'
        }
      },
    },
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      title: {
        display: true,
        text: 'Mood',
      },
    },
  };

  const wrapperStyle = {
    height: height
  };

  return (
    <div >
      <Line data={moodData} options={options} style={wrapperStyle} />
    </div>
  );
};

export default MoodGraph;

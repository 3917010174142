import React from "react"

import "src/assets/less/global.less"
import 'bootstrap/dist/css/bootstrap.min.css'


const Filter = (props) => {
  return (
    <div className="filter">
      <div className="filter-type">
        <div className="filter-title">Project Type:</div>
        <div className="filter-list">
          {props.typeOptions.map((type, index) => (
            <a href="#" className={type.selected ? `Btn selected` : `Btn not-selected`}
               key={index} onClick={(e) => props.toggleType(e, type.name)}>{type.name}</a>
          ))}
        </div>
      </div>
      <div className="filter-type">
        <div className="filter-title">Technology:</div>
        <div className="filter-list">
          <a href="#" className={props.allTech ? `Btn selected` : `Btn not-selected`}
             onClick={(e) => props.toggleAllTech(e)}>All</a>
          {props.techOptions.map((tech, index) => (
            <a href="#" key={index} className={tech.selected ? `Btn selected` : `Btn not-selected`}
               onClick={(e) => props.toggleTech(e, tech.name)}>{tech.name}</a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
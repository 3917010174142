import React from "react"
import { Link } from 'gatsby'

const getTextColorClass = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance >= 0.5 ? 'text-black border-black' : 'text-white border-white';
};

const Colors = ({ colors }) => {
    if (colors.length > 0) {
        return (
            <div className="mt-3">
                <p>Colors:</p>
                <div className="flex flex-row flex-wrap">
                    {colors?.map((color, index) => (
                        <Link 
                            to={`/image-color/${color?.RgbColor?.name}`} 
                            className={`p-2 rounded-md mt-2 mr-2 ml-0 mb-2 ${getTextColorClass(color?.RgbColor?.hex)}`}
                            key={index}
                            style={{ backgroundColor: color?.RgbColor?.hex }}
                        >
                            {color?.RgbColor?.name}
                        </Link>
                    ))}
                </div>
            </div>
        );
    }
    return null;
};

export default Colors;

import React, { useState, useEffect, useMemo } from "react"
import { useLocation, navigate, Link } from "@reach/router";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Footer from "src/components/Footer";
import Breadcrumb from "src/components/Breadcrumb";
import ImageGallery from "src/components/images/ImageGallery";
import ToggleColorFrequencyGraph from "src/components/graphs/ToggleColorFrequencyGraph.js";
import Pagination from "src/components/Pagination";
import { Container, Col, Row } from "react-bootstrap";
import axios from 'axios';

const ColorNamePage = ({ data, pageContext }) => {
    const { name, hex, images } = pageContext; 
    const colorName = name;
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const pageFromUrl = parseInt(searchParams.get('page'), 10) || 1;

    const imagesPerPage = 10; // Set to your desired number of images per page
    const [isLoading, setIsLoading] = useState(false);
    const [imageData, setImageData] = useState(images);
    const [page, setPage] = useState(pageFromUrl);

    const getImages = async (currentPage) => {
    let apiUrl = `${process.env.GATSBY_API_URL}image/color/${colorName}?pagesize=${imagesPerPage}&page=${currentPage}`;

    try {
      setIsLoading(true);
      const response = await axios.get(apiUrl);
      const rawImages = response.data.images;

      const rest = response.data;
      const images = rawImages.map(item => ({ node: { hash: item.hash, createdAt: item.createdAt }}));
      const fullImageObject = { items: images, totalItems: rest.totalItems, totalPages: rest.totalPages, currentPage: rest.currentPage };
      setImageData(fullImageObject);
    } catch (error) {
      console.error("Failed to fetch images:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getImages(page);
    setPage(pageFromUrl);
  }, [pageFromUrl, page]);

  const handlePageChange = (newPage) => {
    window.location.href = `/image-color/${colorName}?page=${newPage}`;
    window.scrollTo(0, 0);
  };


  const metadataTitle = "Images";
  const metadataDescription = "All of my motha fuckin pics";
  const metadataImg = ""; 

  return (
    <Layout>
      <SEO title={metadataTitle} description={metadataDescription} imageURL={metadataImg} />
      <Container>
        <Row>
            <Col xs={12}>
              <Breadcrumb />
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <h1 className="mb-4" style={{color: hex}}>{colorName}</h1>
                  <ToggleColorFrequencyGraph color={colorName} hex={hex} uploads={pageContext.images} />
                  <hr />
                  <ImageGallery data={imageData?.items} />
                </>
              )}
            </Col>
        </Row>
        <Row>
          {!isLoading && (
            <Col xs={12} className="text-center">
              <Pagination 
                  currentPage={imageData?.currentPage} 
                  totalPages={imageData?.totalPages} 
                  onPageChange={handlePageChange}
              />
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </Layout>
  );
};

export default ColorNamePage;

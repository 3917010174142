import React from "react";
import { Link } from "gatsby";

import "src/assets/less/global.less";

const Breadcrumb = ({ type }) => {
  let urlArr = [];
  const isBrowser = typeof window !== "undefined";

  const toWord = (str) => {
    if (str) {
      return decodeURIComponent(str.replace(/-/g, ' ')).trim();
    }
    return '';
  };

  if (isBrowser) {
    let urlStr = window.location.pathname;
    if (urlStr.endsWith('/')) {
      urlStr = urlStr.slice(0, -1);
    }
    urlArr = urlStr.split('/').slice(1);
  }

  const schema = type === "image" ? [
    { name: "images", type: "anchor", url: "/images" },
    { name: urlArr[urlArr.length - 2], type: "span" },
  ] : urlArr.map((segment, index) => ({
      name: toWord(segment),
      type: index === urlArr.length - 1 ? "span" : "anchor",
      url: `/${urlArr.slice(0, index + 1).join('/')}`,
    }));

    const className = `breadcrumb ${type === "image" ? "" : "mb-4"}`;

  return (
    <div className={className}>
      {schema.length > 0 && (
        <>
          <span style={{ paddingRight: 5 }}>
            <Link to="/">home</Link> &gt;
          </span>
          {schema.map((item, index) => {
            if (item.type === "anchor") {
              return (
                <span style={{ paddingRight: 5 }} key={index}>
                  <Link to={item.url}>{toWord(item.name)}</Link> &gt;
                </span>
              );
            } else if (item.type === "span") {
              return <span key={index}>{toWord(item.name)}</span>;
            }
            return null;
          })}
        </>
      )}
    </div>
  );
};

export default Breadcrumb;

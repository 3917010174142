import React from "react"
import ProjectContent from "src/components/ProjectContent"
 
 
const ProjectList = ({projectList, options}) => {
  // sort by year in reverse order.
  const newProjectList = projectList.sort((a, b) => {
    if(parseInt(a.year) > parseInt(b.year)) {
      return -1
    } else {
      return 1
    }
  })
  
  if(projectList.length > 0) {
    return (
      <div className="project-section">
        {newProjectList.map((section, i) => {
          section.projects.sort((a,b) => {
            return a.type === `Commercial` ? -1 : b.type === `Commercial` ? 1 : 0
          })

          return (
            <React.Fragment key={i}>
              <h2>{section.year}</h2>
              {section.projects.map((project, index) => <ProjectContent project={project} key={index} />)}
            </React.Fragment>
          )
        })}
      </div>
    )
  } else {
    return (
      <div className="project-section">
        No projects matched your search query.
      </div>
    )
  }
 }
 
 export default ProjectList
import React, { useState, useRef, useEffect } from "react"
import Image from 'src/components/images/Image'

const ImageGallery = (props) => {
  const items = props.data

  return (
    <div>
      {items?.map((item, index) => {
        return (
        <div className="mb-4 xl:mb-16" key={index}>
            <Image data={ item.node } />
          </div>
        )
      })}
    </div>
  )
}

export default ImageGallery

import React, { useState, useEffect } from "react";
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Breadcrumb from "src/components/Breadcrumb"
import TagsOverTime from "src/components/graphs/TagsOverTime"
import Footer from "src/components/Footer"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from 'gatsby';
import Select from 'react-select';
import { graphql } from "gatsby";
import CustomRemoveIcon from "src/components/select/CustomRemoveIcon"
import CustomDropdownIndicator from "src/components/select/CustomDropdownIndicator"


const TagsPage = ({ pageContext, data }) => {
  const [graphTags, setGraphTags] = useState([])

  const tags = pageContext.mappedImagesByTag
  const mapped = Object.keys(tags)
  .map(key => ({
    key,
    length: tags[key].length
  }))
  const ordered = mapped.sort((a,b) => b.length - a.length)
  const selectOptions = ordered
  .map(item => ({
    value: item.key,
    label: `${item.key} (${item.length})`
  }));

  const defaultSelectedArr = selectOptions.slice(0, 4)

  useEffect(() => {
    const selected = defaultSelectedArr.map(item => ({ name: item.value, items: tags[item.value]}));
    setGraphTags(selected )
  }, []);

  const handleChange = (selectedOption) => {
    if (selectedOption.length <= 5) {
      const selected = selectedOption.map(item => ({
        name: item.value, 
        items: tags[item.value]
      }));
      setGraphTags(selected);
    } else {
      alert('You can only select up to 5 tags - otherwise the graph will become illegible.');
    }
  }
  

  const metadataTitle = data.wpPage.metadata.metadataTitle;
  const metadataDescription = data.wpPage.metadata.metadataTitle;
  const metadataImg = data.wpPage.metadata.metadataImage;

  return (
    <Layout>
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
      <Container>
        <Row>
          <Col xs={12}>
            <Breadcrumb />
            <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
            <h2 className="mb-4">
              Tags over time:
            </h2>
            <p>You can select up to 5 tags and see the frequency and dates of images uploaded with those given tags over time via the graph.</p>
            <p>Clicking on the <code>✕</code> hides a tag, and clicking on the <code>▼</code> on the right hand side shows all the tags you can select.</p>
            <p>You can view all of the tags by selecting them in the dropdown and then clicking on the links here:</p>
            { graphTags.map((tag, index) => (
              <>
                <Link to={`/images/tags/${tag.name}`}>{tag.name}</Link>
                {index < graphTags.length - 1 ? <span className="mx-2">,</span> : <></>}
              </>
            ))}
            <Select
              defaultValue={defaultSelectedArr}
              isMulti
              name="colors"
              options={selectOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
              value={graphTags.map(tag => ({
                value: tag.name,
                label: `${tag.name} (${tags[tag.name].length})`
              }))}
              components={{ MultiValueRemove: CustomRemoveIcon,
                            DropdownIndicator: CustomDropdownIndicator 
                         }} 
            />
            <TagsOverTime data={graphTags} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
query wpTagsQuery {
  wpPage(title: {eq: "TAGS"}) {
    metadata {
      metadataTitle
      metadataImage {
        localFile {
          url
        }
      }
      metadataDescription
    }
    title
    content
  }
}`

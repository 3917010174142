import React, { useState } from "react";
import AllColorGraph from 'src/components/graphs/AllColorGraph';

const ToggleAllColorGraph = ({ moodData, colorData }) => {
    const [graphIsVisible, setGraphIsVisible] = useState(false);

    const click = async (e) => {
        e.preventDefault();

        setGraphIsVisible(!graphIsVisible);
    }

    return (
        <div className="mb-2">
            <a href="#" onClick={click}>
                {( graphIsVisible ? `Hide ` : 
                                    `Show `
                ) + 'color upload graph'}
            </a>
            {graphIsVisible && <AllColorGraph moodData={moodData} colorData={colorData} />}
        </div>
    )
}

export default ToggleAllColorGraph;

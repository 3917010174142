import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const renderPaginationButtons = () => {
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    const paginationButtons = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <button 
          key={i} 
          onClick={() => onPageChange(i)} 
          disabled={i === currentPage}
          className={`px-3 py-1 mx-1 ${i === currentPage ? 'text-black' : 'text-black underline'}`}
          
        >
          {i === currentPage ? `[${i}]` : i}
        </button>
      );
    }

    if (startPage > 1) {
      paginationButtons.unshift(
        <button 
          key="start" 
          onClick={() => onPageChange(1)}
          className="px-3 py-1 mx-1 text-black underline cursor-pointer"
          
        >
          1 ...
        </button>
      );
    }
    if (endPage < totalPages) {
      paginationButtons.push(
        <button 
          key="end" 
          onClick={() => onPageChange(totalPages)}
          className="px-3 py-1 mx-1 text-black underline cursor-pointer"
        >
          ... {totalPages}
        </button>
      );
    }

    return paginationButtons;
  };

  return (
    <div className="flex justify-center my-4">
      {renderPaginationButtons()}
    </div>
  );
};

export default Pagination;

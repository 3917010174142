import React, { useState, useRef, useEffect } from "react"
// import Image from 'src/components/images/Image'
import { Link } from 'gatsby'

const Image = (props) => {
    const API_URL = process.env.GATSBY_API_URL;
    const image = props.data

    if(image) {
      return (
        <div>
            <Link to={`/images/${image.hash}`} state={{ image }} >
                <img src={`${API_URL}image/fuck/${image.hash}.jpg`} />
            </Link>
        </div>
      )
    } else {
      return <></>
    }
}

export default Image
import React, { useEffect, useState } from 'react';
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import { Container, Col, Row } from "react-bootstrap";
import Breadcrumb from 'src/components/Breadcrumb';
import axios from 'axios';

const WhatTheCookie = ({ params }) => {
  const { pageUrl } = params;
  const API_URL = process.env.GATSBY_API_URL;

  const [pageHtml, setPageHtml] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('The page is being retrieved');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    let dotCount = 0;
    const dotInterval = setInterval(() => {
      setLoadingMessage(`The page is being retrieved${'.'.repeat(dotCount % 4)}`);
      dotCount++;
    }, 250);

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}what/${pageUrl}`);
        clearInterval(dotInterval); // Clear the interval for the loading animation

        if (response.data.success) {
          setPageHtml(response.data.data.pageHtml);
          setIsLoading(false);
        } else {
          setErrorMessage('Invalid url or error retrieving page');
        }
      } catch (error) {
        console.error("Failed to fetch page data:", error);
        clearInterval(dotInterval); // Clear the interval upon error
        setIsLoading(false);
        setErrorMessage('Invalid url or error retrieving page');
      }
    };

    fetchData();

    // Cleanup interval on component unmount
    return () => clearInterval(dotInterval);
  }, [pageUrl]);

  return (
    <>
      <SEO title={`What Da FUCK Is A Cookie??`}/>
      <Container>
        <Row>
          <Col xs={12}>
            {isLoading ? (
              <p>{loadingMessage}</p>
            ) : errorMessage ? (
              <p>{errorMessage}</p>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: pageHtml }} />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WhatTheCookie;

import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"


const BlogList = (props) => {
  const formatDate = (date) => {
    const year = date.slice(0, 4);
    const month = parseInt(date.slice(5, 7));
    const day = date.slice(8, 10);

    const months = [`January`, `February`, `March`, `April`, `May`, `June`, 
                    `July`, `August`, `September`, `October`, `November`, `December`
                   ];

    return `${months[month - 1]} ${day}, ${year}`;
  }

  const blogPostsByDate = props.blogs.sort((a, b) => {
    const dateA = new Date(a.node.date);
    const dateB = new Date(b.node.date);
    return dateB - dateA;
  });

  return (
    <div className={`blog-list`}>
      {blogPostsByDate.map((blog, index) => {
        return (
          <div className={`blog-post`} key={index}>
            <Link to={`/blog/${blog.node.slug}`}>
              {blog.node.title}
            </Link>
            <div className={`blog-post-date`}>
              Posted on {formatDate(blog.node.date)}
            </div>
            <div className={`blog-post-description`}>
              {blog.node.metadata.metadataDescription}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default BlogList
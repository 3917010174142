import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

// Register Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const aggregateImagesByMonth = (images) => {
  const monthCounts = {};

  // Collect all image dates and determine the date range
  const dates = images.map((img) => new Date(img.createdAt));
  const minDate = new Date(Math.min(...dates));
  const maxDate = new Date(Math.max(...dates));

  // Set minDate to the first day of the month
  minDate.setDate(1);

  // Iterate over every month between minDate and maxDate, initializing with 0
  for (let d = new Date(minDate); d <= maxDate; d.setMonth(d.getMonth() + 1)) {
    const month = `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}`; // Format: yyyy-MM
    monthCounts[month] = 0;
  }

  // Populate the counts for the actual image upload dates
  images.forEach((image) => {
    const date = new Date(image.createdAt);
    const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Format: yyyy-MM
    monthCounts[month]++;
  });

  // Convert the counts object into a sorted array of { x, y } objects
  return Object.keys(monthCounts)
    .sort()
    .map((month) => ({
      x: month,
      y: monthCounts[month],
    }));
};


const AggregatedLineChart = ({ colorData }) => {
  // Create datasets for each color
  const datasets = colorData.map(({ rgbColor, images }) => ({
    label: rgbColor.name,
    // data: aggregateImagesByDay(images),
    data: aggregateImagesByMonth(images),
    borderColor: rgbColor.hex,
    backgroundColor: rgbColor.hex, // Semi-transparent fill
    fill: true,
    tension: 0.4, // Smooth line effect
    yAxisID: 'y',
  }));

  const data = { datasets };

  const options = {
    animation: false,
    scales: {
      x: {
        type: 'time',
        time: { unit: 'month' },
        title: { display: true, text: 'Date' },
      },
      y: {
        type: 'linear',
        position: 'left',
        stacked: true,
        title: { display: true, text: 'Image Uploads (Count)' },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
    },
  };

  return (
    <div style={{ minHeight: '800px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default AggregatedLineChart;

import React, { useState, useEffect } from "react"

import { paper } from 'paper'
import mouse from "src/assets/images/mouse.png"
import smallMouse from "src/assets/images/smallMouse.png"

import "src/assets/less/paper.less"


const PaperComponent = (props) => {
  const [display, setDisplay] = useState({width: 0, 
                                          height: 0})

  useEffect(() => {
    const width = window.innerWidth
    const height = window.innerHeight


    setDisplay({width: width, 
                height: height })
    
    paper.setup("paper")
    const segments = []

    paper.Raster.prototype.rescale = function(width, height) {
      this.scale(width / this.width, height / this.height);
    }
   
    if(props.pathCoords.length > 0) {
      // const ratio = (display.width / props.pathWidth) * 1.2
      const ratio = display.width / props.pathWidth
      const stretchWidth = ratio * 1.1
      const view = paper.view
  
      let mouseRaster

      if(width > 768) {
        mouseRaster = new paper.Raster(mouse)
      } else {
        mouseRaster = new paper.Raster(smallMouse)
      }

      mouseRaster.onLoad = () => {
        const symbol = new paper.Symbol(mouseRaster)
        const mouseCount =  width > 768 ? 90 : 40
        for(var i = 0; i < mouseCount; i ++) {
          const center = new paper.Point(-150, -150)
          symbol.place(center)
        }

        let biggestY = 0
        props.pathCoords.forEach((point) => {
          if(point.y > biggestY) biggestY = point.y
        })

        const yDiff = (height - (biggestY * ratio))
        const fitsOnScreen = height - biggestY * ratio - 60 > 0

        props.pathCoords.forEach((point) => {
          if(point.y > biggestY) {
            biggestY = point.y
          }

          if(fitsOnScreen) {
            segments.push([point.x * stretchWidth, (point.y * ratio + yDiff - 60) ])
          } else {
            const newRatio = (height - 60) / biggestY
            const newYDiff = height - (biggestY * newRatio)
            segments.push([point.x * stretchWidth, (point.y * newRatio + newYDiff - 30) ])

          }
      
        })
  
        let path = new paper.Path()
        path.name = `line`
        path.style = {strokeColor: 'blue', strokeWidth: 80}
        path.opacity = 0.0
        path.addSegments(segments)
        path.simplify(10)
        
        path.fullySelected = true
        let offset = 0
  
        view.onFrame = ((event) =>  {
          for(let i = 0; i < mouseCount; i ++ ) {
            let item = paper.project.activeLayer.children[i]
  
            if(path.name === `line`) {
              if(width > 768) {
                if(offset - i * 30 > 100) {
                  let currPoint = path.getPointAt(offset - (i * 30) - 90 )
                  
                  if(currPoint) {
                    if(currPoint.x !== 0 && currPoint.y !== 0) {
                      if(item) {
                        item.position = currPoint
                      }
                    }
                  } 
                }
              } else {
                if(offset - i * 15 > 100) {
                  const len = path.length
                  const daVal = offset - (i * 15) - 90 
                  let currPoint = path.getPointAt(offset - (i * 15) - 90 )
                  if(currPoint) {
                    if(currPoint.x !== 0 && currPoint.y !== 0) {
                      if(item) {
                        item.position = currPoint
                      }
                    }
                  } 
                }
              }
  
            } else {
              return
            }
  
            const offsetVal = width > 768 ? event.delta * 3 : event.delta * 5
            
            offset += offsetVal
          
          }
          if(window.resetAnimation) {
            offset = 0
            window.resetAnimation = false
            for(var i = 0; i < mouseCount; i ++) {
              let item = paper.project.activeLayer.children[i]
              item.position = new paper.Point(-150, -150)
            }
          }
        })
  
        let hitOptions = {
          segments: true,
          stroke: true,
          fill: true,
          // tolerance: width > 768 ? 150 : 75
          tolerane: 2
        }
        
  
  
        var segment, handle;
        var movePath = false;
        view.onMouseDown = ((event) => {
          
          segment = handle = null;
          var hitResult = paper.project.hitTest(event.point, hitOptions)
  
          if (event.modifiers.shift) {
            if (hitResult.type === 'segment') {
              hitResult.segment.remove()
            }
          return
        }
  
        if (hitResult) {
          path = hitResult.item;
          if (hitResult.type === 'segment') {
            segment = hitResult.segment;
          } else if (hitResult.type === 'stroke') {
            var location = hitResult.location;
            segment = path.insert(location.index + 1, event.point);
            path.smooth();
          }
          else if (hitResult.type === 'handle-in') {
            handle = hitResult.segment.handleIn;
          }
          else if (hitResult.type === 'handle-out') {
            handle = hitResult.segment.handleOut;
          }
          movePath = hitResult.type === 'fill';
        }
        if (movePath)
          paper.project.activeLayer.addChild(hitResult.item);
        })
  
        view.onMouseDrag = ((event) => {
          if (segment) {
            segment.point = event.point;
            path.smooth();
          }
  
          if (handle) {
            handle.x += event.delta.x;
            handle.y += event.delta.y;
          }
  
  
          if (movePath) {
            path.position += event.delta;
          }
        })
  
      } 
    }
  }, [props.pathCoords])

  
// }

  return(
    <canvas id="paper"

            style={{ position: `absolute`, 
                     top: 0,
                     left: 0, 
                     width: display.width, 
                     height: display.height }}>                
    </canvas>
  )
}

export default PaperComponent
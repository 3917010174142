import React, { useState, useEffect } from "react"
import { useLocation, navigate } from "@reach/router";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import ImageGallery from "src/components/images/ImageGallery";
import Pagination from "src/components/Pagination";
import ToggleTagFrequencyGraph from "src/components/tags/ToggleTagFrequencyGraph";
import Footer from "src/components/Footer";
import { Container, Col, Row } from "react-bootstrap";
import axios from 'axios';

const TagsGallery = ({pageContext, location}) => {
  const searchParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(searchParams.get('page'), 10) || 1;
  const yearFromUrl = searchParams.get('year');
  const monthFromUrl = searchParams.get('month');

  const monthMap = {
    'jan': 1,
    'feb': 2,
    'mar': 3,
    'apr': 4,
    'may': 5,
    'jun': 6,
    'jul': 7,
    'aug': 8,
    'sep': 9,
    'oct': 10,
    'nov': 11,
    'dec': 12
  };

  const pathnames = location.pathname.split(`/`);
  const pathname = pathnames.pop();

  const imagesPerPage = 10; // You can set this to whatever number of images you want per page
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [page, setPage] = useState(pageFromUrl);
  const blurb = pageContext.blurb?.node?.content;

  const getImages = async (currentPage) => {
    let apiUrl = `${process.env.GATSBY_API_URL}image/tagName/${pathname}?pagesize=${imagesPerPage}&page=${currentPage}`;
    if (yearFromUrl) {
      apiUrl += `&year=${yearFromUrl}`;
    }
    if (monthFromUrl && monthMap[monthFromUrl.toLowerCase()]) {
      const monthNumber = monthMap[monthFromUrl.toLowerCase()];
      apiUrl += `&month=${monthNumber}`;
    }

    try {
      setIsLoading(true)
      const response = await axios.get(apiUrl);
      const rawImages = response.data.images.items;
      const rest = response.data.images
      const images = rawImages.map(item => ({ node: { hash: item.hash, createdAt: item.createdAt }}))

      const fullImageObject = { items: images, totalItems: rest.totalItems, totalPages: rest.totalPages, currentPage: rest.currentPage }
      setImageData(fullImageObject);
    } catch (error) {
      console.error("Failed to fetch images:", error);
    }
    setIsLoading(false)
  };

  useEffect(() => {
    getImages(page);
    setPage(pageFromUrl);
  }, [pageFromUrl, page]);

  const handlePageChange = (newPage) => {
    const currentParams = `page=${newPage}` + (yearFromUrl ? `&year=${yearFromUrl}` : '') + (monthFromUrl ? `&month=${monthFromUrl}` : '');
    window.location.href = `/images/tags/${pathname}?${currentParams}`;
    window.scrollTo(0, 0);
  };

  const generateTitle = () => {
    let title = pageContext.tagName;
    if (yearFromUrl) {
      if (monthFromUrl) {
        title += ` - <span style="font-style: italic">${monthFromUrl[0].toUpperCase() + monthFromUrl.slice(1)} ${yearFromUrl}</span>`;
      } else {
        title += ` - <span style="font-style: italic">${yearFromUrl}</span>`;
      }
    }
    return title;
  };

  const metadataTitle = `${pageContext.tagName}`;
  const metadataDescription = `Images tagged as ${pageContext.tagName}`
  const metadataImg = ``

  return (
    <Layout>
      <SEO title={metadataTitle}
                 description={metadataDescription} 
                 imageURL={metadataImg} />
      <Container>
        <Row>
            <Col xs={12}>
              <Breadcrumb />
              {isLoading ? (
                <div>Loading...</div>
              ) :
                <>
                <h1 className="mb-4" dangerouslySetInnerHTML={{ __html: generateTitle() }}></h1>
                {blurb ? 
                <div className="mb-4 border-b border-black">
                  <div dangerouslySetInnerHTML={{__html: blurb}}></div>
                  <ToggleTagFrequencyGraph tagName={ pageContext.tagName }/>
                </div> :
                <div className="mb-4 border-b border-black">
                  <ToggleTagFrequencyGraph tagName={ pageContext.tagName }/>
                </div>
                }
                <ImageGallery data={imageData.items} />
                </>
              }
            </Col>
        </Row>
        <Row>
        {isLoading ? ( <></> ) :
          <Col xs={12} className="text-center">
            <Pagination 
                currentPage={imageData.currentPage} 
                totalPages={imageData.totalPages} 
                onPageChange={handlePageChange}
            />
          </Col>
        }
        </Row>
      </Container>
      <Footer />
    </Layout>
  );
};

export default TagsGallery;
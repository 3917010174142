import React, { useState, useEffect, useRef } from "react"
import { Container, Col, Row } from "react-bootstrap"
import Filter from "src/components/Filter"
import ProjectList from "src/components/ProjectList"
 
 
const ProjectsFilter = ({projects}) => {
  const [projectData, setProjectData] = useState([])
  const [typeOptions, setTypeOptions] = useState([{name: `Commercial projects`, selected: true}, {name: `Personal projects`, selected: true}])
  const [techOptions, setTechOptions] = useState([])
  const [projectsByYear, setProjectsByYear] = useState([])
  const [allTech, setAllTech] = useState(true)

  const [projectList, setProjectList] = useState([])

  useEffect(() => {
    reset()
  }, [])

  const reset = () => {
    //set project data
    const projectArr = resetProjectData()
    //sort by year
    resetProjectByYear(projectArr)
    // set tech list
    resetTechList(projectArr)
  }

  const resetProjectData = () => {
    const projectArr = []
    projects.forEach(project => {
      const newProj = project.node.project
      newProj.title = project.node.title
      projectArr.push(newProj)
    })

    setProjectData(projectArr)
    return projectArr
  }

  const resetProjectByYear = (projectArr) => {
    const roughByYear = projectArr.reduce(function(prev, curr) {
      prev[curr.year] = prev[curr.year] || []
      prev[curr.year].push(curr)
      return prev
    }, Object.create(null))
    let byYear = []
    for(const [key, val] of Object.entries(roughByYear)) {
      byYear.push({year: key, projects: val})
    }
    setProjectsByYear(byYear)
  }

  const resetTechList = (projectArr) => {
    const allOptions = []
    projectArr.forEach(project => project.techList.forEach(techItem => allOptions.push(techItem.tech)))
    const uniqueOptions = allOptions.filter((val, i, self) => self.indexOf(val) === i)
    
    const optionsObj = []
    uniqueOptions.forEach(option => optionsObj.push({name: option, selected: false}))
    setTechOptions(optionsObj)
  }

  const toggleAllTech = (e) => {
    e.preventDefault()
    if(!allTech) {
      setAllTech(true)
      resetTechList(projectData)
      const newTechOptions = techOptions.map(option => {
        return ({...option, selected: false})
      })
      updateProjects(typeOptions, newTechOptions)
    } 
  }

  const toggleTech = (e, tech) => {
    e.preventDefault()
    setAllTech(false)
    const newOptions = techOptions.map(option => {
      if(option.name === tech) {
        return ({...option, selected: !option.selected})
      } else {
        return ({...option, selected: false})
      }
    })
    setTechOptions(newOptions)
    // updateProjects(`tech`, newOptions)
    updateProjects(typeOptions, newOptions)
  }

  const toggleType = (e, type) => {
    e.preventDefault()
    const newOptions = typeOptions.map(option => {
      if(option.name === type) {
        return ({...option, selected: !option.selected})
      } else {
        return({...option})
      }
    })
    setTypeOptions(newOptions)
    updateProjects(newOptions, techOptions)
  }

  // const updateProjects = (type, options) => {
  const updateProjects = (typeOptions, techOptions) => {
    let selectedOptions = []
    let selectedTypeOptions = []
    let selectedTechOptions = []
    typeOptions.forEach(option => {
      if(option.selected) {
        selectedOptions.push(option.name)
        selectedTypeOptions.push(option.name)
      }
    })
    techOptions.forEach(option => {
      if(option.selected) {
        selectedOptions.push(option.name)
        selectedTechOptions.push(option.name)
      }
    })
    
    if(selectedTechOptions.length === 0) {
      setAllTech(true)
      // reset()
    }

    let newProjectData = []
    if(selectedTypeOptions.length > 0 && selectedTechOptions.length > 0) {
      projectData.forEach(project => {
        if(selectedOptions.includes(`${project.type} projects`)) {
          project.techList.forEach(item => {
            if(selectedOptions.includes(item.tech)) {
              newProjectData.push(project)
            }
          })
        }
      })
    } else {
      projectData.forEach(project => {
        if(selectedOptions.includes(`${project.type} projects`)) {
              newProjectData.push(project)
        }
      })
    }
    resetProjectByYear(newProjectData)
  }

  return (
    <div className="projects">
      <Filter typeOptions={typeOptions} 
              techOptions={techOptions}
              allTech={allTech}
              toggleAllTech={toggleAllTech}
              toggleTech={toggleTech}
              toggleType={toggleType}
               />
      <div className="project-section-list">
      <Row>
        <Col xs={12} lg={10}>
          <ProjectList projectList={projectsByYear} options={{typeOptions, techOptions}} />
        </Col>
      </Row>
        
      </div>
    </div>
    )
 }
 
 export default ProjectsFilter
 


import React, { useState } from "react";
import axios from "axios";
import TagsOverTime from 'src/components/graphs/TagsOverTime';

const ToggleTagFrequencyGraph = (props) => {
    const tagName = props.tagName;
    const [graphIsVisible, setGraphIsVisible] = useState(false);
    const [graphDataIsLoading, setGraphDataIsLoading] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);  // Flag to track if data has been fetched

    const click = async (e) => {
        e.preventDefault();

        if (!graphIsVisible && !dataFetched) {
            setGraphDataIsLoading(true);
            try {
                const response = await axios.get(`${process.env.GATSBY_API_URL}tag/${tagName}`);
                const mappedData = mapResponseToGraphData(response.data);
                
                setTagsData(mappedData);
                setDataFetched(true);
                setGraphDataIsLoading(false);
            } catch (error) {
                console.error('Failed to load data:', error);
                setGraphDataIsLoading(false);
            }
        }
        setGraphIsVisible(!graphIsVisible);
    }

    const mapResponseToGraphData = (responseData) => {
        const tag = {
            name: tagName,
            items: responseData.map(item => ({
                node: {
                    createdAt: item.image ? item.image.createdAt : item.createdAt
                }
            }))
        };
        return [tag];
    };

    return (
        <div className="mb-2">
            <a href="#" onClick={click}>
                {graphDataIsLoading ? "Loading..." : (graphIsVisible ? `Hide ` : `Show `) + 'upload frequency graph for images tagged with `' + tagName + '`'}
            </a>
            {graphIsVisible && !graphDataIsLoading && <TagsOverTime data={tagsData} />}
        </div>
    )
}

export default ToggleTagFrequencyGraph;

import { navigate } from "gatsby"
import React, {useRef, useState, useEffect, useCallback, useLayoutEffect } from "react"

const CartoonModal = (props) => {
  const [size, setSize] = useState({x: -1, y: -1})
  const [bgStyle, setBgStyle] = useState(``)

  const imgRatio = props.cartoon.imagePng.mediaDetails.width/props.cartoon.imagePng.mediaDetails.height
  let location = props.location
  const theToon = props.cartoonPos

  if(!location) {
    location = {
      left: 0,
      top: 0,
      width: 20, 
      height: 20
    }
  }

  let revertStyle = location
  
  if(theToon.length > 0 && props.cartoonSlug) {
    revertStyle = theToon.find((item) => {
      if(item.slug === props.cartoonSlug) return item
    })

    revertStyle = {
      ...revertStyle,
      left: revertStyle.x,
      top: revertStyle.y,
      width: revertStyle.width,
      height: revertStyle.width / imgRatio
    }
  }
  const [cartoonStyle, setCartoonStyle] = useState({ position: `absolute`, 
                                                     left: location.x, 
                                                     top: location.y - 20, 
                                                     width: location.width,
                                                     height: location.height })

  const modalImage = useRef(null)
  const imagePlaceholder = useRef(null)

  useEffect(() => {
    if(!props.isOpen) {
      closeModal()
    }

    document.addEventListener(`keyup`, tryCloseModal)

    setSize({x: window.innerWidth, y: window.innerHeight})
    if(window.innerWidth < 768) {
      setCartoonStyle({...cartoonStyle, top: location.y - 20})
    }

    let placeholderHeight = window.innerHeight * 0.75
    let placeholderWidth = placeholderHeight * imgRatio
    let placeholderLeft = window.innerWidth * 0.5 - placeholderWidth * 0.5
    if(window.innerWidth < placeholderWidth) {
      placeholderWidth = window.innerWidth - 30
      placeholderHeight = placeholderWidth / imgRatio
      placeholderLeft = 15
    }


    setBgStyle({backgroundColor: `rgba(0, 0, 0, 0.6)`, backdropFilter: `blur(4px)`})

    setCartoonStyle({...cartoonStyle, left: placeholderLeft,
      top: window.innerHeight * 0.125,
      width: placeholderWidth,
      height: placeholderHeight })

  }, [props.cartoon, props.isOpen])

  const tryCloseModal = (e) => {
    if(e.keyCode === 27){
      closeModal() 
    }
  }

  const closeModal = () => {
    setBgStyle({backgroundColor: `rgba(0, 0, 0, 0)`, backdropFilter: `blur(0px)` })
    setCartoonStyle({...cartoonStyle, 
                      left: revertStyle.x, 
                      top: revertStyle.y, 
                      width: revertStyle.width,
                      height: revertStyle.height })
    setTimeout(function() {
      
      navigate('/cartoons', { state: { disableScrollUpdate: true }})
    }, 450)
    
  }

  return (
    <div className={`cartoon-modal`} style={{top: props.scrollHeight, width: size.x, height: size.y, ...bgStyle}} onClick={closeModal}>
      <img className={`cartoon-modal--img`} 
           style={cartoonStyle}
           ref={ modalImage } 
           src={props.cartoon.imagePng.sourceUrl} 
           srcSet={props.cartoon.imagePng.srcSet} />
      <button className={`cartoon-modal--close`} onClick={() => closeModal()} >close</button>

    </div>
  )
}

export default CartoonModal
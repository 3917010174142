import React from "react"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Breadcrumb from "src/components/Breadcrumb"
import MoodImageFrequencyGraph from "src/components/graphs/MoodImageFrequencyGraph";
import MonthGrid from "src/components/archive/MonthGrid";
import Footer from "src/components/Footer"
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby";

const groupAndSortTags = (tags) => {
    // Create an object to count occurrences of each tag
    const tagCounts = tags.reduce((acc, tag) => {
        if (acc[tag.tagName]) {
            acc[tag.tagName]++;
        } else {
            acc[tag.tagName] = 1;
        }
        return acc;
    }, {});

    const sortedTags = Object.entries(tagCounts)
    .filter(([tagName, count]) => count > 1)
    .map(([tagName, count]) => ({
        tagName,
        count
    }));

    

    sortedTags.sort((a, b) => b.count - a.count);

    return sortedTags;
}

const slugToTitle = (slug) => {
    const map = {
        'jan': 'January', 'feb': 'February', 'mar': 'March', 'apr': 'April',
        'may': 'May', 'jun': 'June', 'jul': 'July', 'aug': 'August',
        'sep': 'September', 'oct': 'October', 'nov': 'November', 'dec': 'December'
    };
    const parts = slug.split('-');
    if (parts.length === 2) {
        const [month, year] = parts;
        return `${map[month]} ${year}`;
    } else {
        return slug;
    }
}

const calculateMonthUrls = (currentSlug) => {
    const monthMap = {
        'jan': 'January', 'feb': 'February', 'mar': 'March', 'apr': 'April',
        'may': 'May', 'jun': 'June', 'jul': 'July', 'aug': 'August',
        'sep': 'September', 'oct': 'October', 'nov': 'November', 'dec': 'December'
    };
    const monthNumbers = {
        'jan': 1, 'feb': 2, 'mar': 3, 'apr': 4, 'may': 5, 'jun': 6,
        'jul': 7, 'aug': 8, 'sep': 9, 'oct': 10, 'nov': 11, 'dec': 12
    };

    const parts = currentSlug.split('-');
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // JavaScript months are 0-indexed

    if (parts.length === 2) {
        // Month-Year navigation
        const [month, year] = parts;
        const monthNum = monthNumbers[month];
        const yearNum = parseInt(year, 10);

        const previousYear = monthNum === 1 ? yearNum - 1 : yearNum;
        const nextYear = monthNum === 12 ? yearNum + 1 : yearNum;

        const previousMonth = monthNum === 1 ? 'dec' : Object.keys(monthNumbers).find(key => monthNumbers[key] === monthNum - 1);
        const nextMonth = monthNum === 12 ? 'jan' : Object.keys(monthNumbers).find(key => monthNumbers[key] === monthNum + 1);

        const previousMonthSlug = `${previousMonth}-${previousYear}`;
        const nextMonthSlug = `${nextMonth}-${nextYear}`;

        return {
            previousMonthUrl: yearNum > 2020 || (yearNum === 2020 && monthNum > 1) ? `/archive/${previousMonthSlug}` : null,
            nextMonthUrl: yearNum < currentYear || (yearNum === currentYear && monthNum < currentMonth) ? `/archive/${nextMonthSlug}` : null,
            previousMonthName: yearNum > 2020 || (yearNum === 2020 && monthNum > 1) ? `${monthMap[previousMonth]} ${previousYear}` : null,
            nextMonthName: yearNum < currentYear || (yearNum === currentYear && monthNum < currentMonth) ? `${monthMap[nextMonth]} ${nextYear}` : null
        };
    } else {
        // Year-only navigation
        const yearNum = parseInt(currentSlug, 10);

        return {
            previousMonthUrl: yearNum > 2020 ? `/archive/${yearNum - 1}` : null,
            nextMonthUrl: yearNum < currentYear ? `/archive/${yearNum + 1}` : null,
            previousMonthName: yearNum > 2020 ? `${yearNum - 1}` : null,
            nextMonthName: yearNum < currentYear ? `${yearNum + 1}` : null
        };
    }
};


const Archive = ( data ) => {
  const archive = data.pageContext.archive
  const imageData = archive.images;
  const moodData = archive.moods;
  const tags = archive.tags
  const slug = archive.slug;
  const parts = slug.split('-');

  const groupedTags = groupAndSortTags(tags)

  const month = data.pageContext.month
  let monthTop
  if(month && month.node.content) {
    monthTop = month.node.content.replace(/\[\[(.+?)\]\]/g, `<a href="/images/$1"><img src="https://gasfried.com/mothafuckin-api/image/fuck/$1.jpg" /></a>`);
  }

  const blogs = data.pageContext.blogs
  const cartoons = data.pageContext.cartoons

  const date = slugToTitle(archive.slug)
  const {
    previousMonthUrl,
    nextMonthUrl,
    previousMonthName,
    nextMonthName
  } = calculateMonthUrls(archive.slug);
  
  const metadata = data.pageContext.month?.node.metadata
  const metadataTitle = metadata ? metadata.metadataTitle : `Adlai Abdelrazaq's Archive - ${date}`
  const metadataDescription = metadata ? metadata.metadataDescription : `Summary of abdelrazaq.com in the month of ${date}`
  const metadataImg = "https://adlai.pw/secretadmindir/wordpress/wp-content/uploads/2022/04/Adlai-Business-Cards-3-02.jpg"
  return (
    <Layout page="blog">
      <SEO title={ metadataTitle }
           description={ metadataDescription }
           imageURL={ metadataImg } />
      <Container>
        <Row>
          <Col xs={12} lg={8} sm={10}>
            <div className={`blog-detail`}>
              <Breadcrumb />
              <p>
                {parts.length === 2 ? (
                    <h2>
                    {parts[0].charAt(0).toUpperCase() + parts[0].slice(1)}{' '}
                    <Link to={`/archive/${parts[1]}`}>{parts[1]}</Link>
                    </h2>
                ) : (
                    <h2>{date}</h2>
                )}
              </p>
              <p className="flex justify-between w-full">
                {previousMonthUrl && <Link to={previousMonthUrl}>{`<< ${previousMonthName}`}</Link>}
                {nextMonthUrl && <Link to={nextMonthUrl}>{`${nextMonthName} >>`}</Link>}
              </p>
              <p>
                <div dangerouslySetInnerHTML={{ __html: monthTop }} />
              </p>
              {parts.length === 1 && <div className="mb-4"><MonthGrid year={slug} /></div>}
              <p>
                <h3>Mood and Image Upload Frequency</h3>
                <div>Number of days I tracked my mood: <b>{moodData.length}</b></div>
                <div>Average mood: <b>
                    {
                        moodData.length > 0
                          ? Math.round(moodData.reduce((acc, item) => acc + item.mood, 0) / moodData.length)
                          : "No data"
                      }
                </b></div>
                <div>Total images uploaded: <b>{imageData.length}</b></div>
                <MoodImageFrequencyGraph imageData={ imageData } moodData={ moodData } />
              </p>
              {blogs.length > 0 ? (
              <p>
                <h3>Writing</h3>
                <ul>
                    { blogs.map(blog => (
                    <li key={blog.id}>
                        <Link to={`/blog/${blog.slug}`}>{blog.title} - { new Date(blog.date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })}
                        </Link>
                    </li>
                    )) }
                </ul>
              </p>
              ) : (<></>)}
              {cartoons.length > 0 ? (
              <p>
                <h3>Cartoons</h3>
                <div className="flex flex-wrap">
                    { cartoons.map(toon => (
                    <div key={toon.id} style={{maxHeight: 400}}>
                        <Link to={`${toon.uri}`}>
                            <img style={{maxHeight: 400 }} src={`${toon.cartoon.imagePng.sourceUrl}`} />
                        </Link>
                    </div>
                    )) }
                </div>
              </p>
              ) : (<></>)}
              <p>
                <h3>Common Image Tags</h3>
                <ul>
                    {groupedTags.map((item, index) => {
                      const isYearOnly = parts.length === 1;
                      const year = isYearOnly ? parts[0] : parts[1];
                      const month = isYearOnly ? '' : parts[0];
                    
                      const url = isYearOnly 
                        ? `/images/tags/${item.tagName}?year=${year}` 
                        : `/images/tags/${item.tagName}?year=${year}&month=${month}`;
                      return (
                        <li>
                            <Link  href={ url ? url : `nothing..` } key={index}>
                                {item.tagName} ({item.count})
                            </Link>
                        </li>
                    )})}
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Layout>
  )
}

export default Archive
import React, { useState, useRef, useEffect } from "react"
import { Link } from 'gatsby'

const Tags = (props) => {
    const tags = props.tags;

    return (
        <div className="mt-3">
            <p>Tags:</p>
            <div className="flex flex-row flex-wrap">
                {tags?.map((tag, index) =>  (
                    <Link to={ `/images/tags/${tag.tagName}` } 
                        className="border-2 border-stone-700 p-2 rounded-md mt-2 mr-2 ml-0 mb-2"
                        key={ index } >
                        {tag.tagName}
                    </Link>
                ))}
            </div>
            
        
        </div>
    )
}

export default Tags
import React, { useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"


const CartoonsList = (props) => {
  const toonRefs= useRef([])
  const [cartoonPos, setCartoonPos] = useState([])
  const [scrollHeight, setScrollHeight] = useState(0)

  const  debounce = (method, delay) => {
    clearTimeout(method._tId)
    method._tId= setTimeout(function(){
        method()
    }, delay)
  }


  useEffect(() => {
    setScrollHeight(window.scrollY)
    let timer = null
    document.addEventListener(`scroll`, (e) => { 
      if(timer !== null) {
        clearTimeout(timer)
      }
      timer = setTimeout(function() {
        // do something
        setScrollHeight(window.scrollY)
        props.toggleModal(false)

        
      }, 150)
       
    })

    const pos = toonRefs.current.map((el, index) => {
      const size = ({x: window.innerWidth, y: window.innerHeight})
      const rect = el.getBoundingClientRect()
      if (rect.width > size.x) {
        const ratio = rect.width/rect.height
        rect.width = size.x
        rect.height = size.y * ratio
      }
      
      return {slug: props.cartoons[index].node.slug, x: rect.x, y: rect.y, width: rect.width, height: rect.height}
    })

    props.setCartoonPos(pos)
    setCartoonPos(toonRefs)
    
    
    // setCartoonPos(pos)
  }, [props.cartoons])

  const clickToonLink = (e, cartoon, scrollHeight) => {
    e.preventDefault()
    setScrollHeight(window.scrollY)

    const pos = toonRefs.current.map((el, index) => {
      const size = ({x: window.innerWidth, y: window.innerHeight})
      const rect = el.getBoundingClientRect()
      if (rect.width > size.x) {
        const ratio = rect.width/rect.height
        rect.width = size.x
        rect.height = size.y * ratio
      }
      
      return {slug: props.cartoons[index].node.slug, x: rect.x, y: rect.y, width: rect.width, height: rect.height}
    })

    const actualToon = pos.find((item) => item.slug === cartoon.slug)
    props.setCartoonPos(pos)
    setCartoonPos(toonRefs)


    navigate(`/cartoons/${cartoon.slug}`, { state: { disableScrollUpdate: true, cartoon: actualToon, scrollHeight: scrollHeight }})
  }

  return (
    <div className={`cartoons`}>
      {props.cartoons.map((cartoon, index) => {
        let opacity = 1
        const obj= cartoon.node.cartoon
        
        if(props.modalToon) {
          
          if(props.modalToon.imagePng.sourceUrl === obj.imagePng.sourceUrl) {
            opacity = 0
          } 
        }

        return (
          <div className={`cartoons-cartoon`} 
               key={index}
               style={{opacity: opacity}}
               >
            <a href={`/cartoons/${cartoon.node.slug}`} onClick={(e) => clickToonLink(e, props.cartoonPos[index], scrollHeight)}>
              <img src={obj.imagePng.sourceUrl}  ref={ el => (toonRefs.current[index] = el) }/>
              {obj.background ? <img className={`image-bg`} src={obj.background.sourceUrl} /> : <></>}
            </a>
          </div>
          
        )
      })}
        
    </div>
  )
}



export default CartoonsList
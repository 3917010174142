import React, { useRef } from "react"
import { Link } from "gatsby"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import ProjectsSorter from "src/components/ProjectsFilter"
import Breadcrumb from "src/components/Breadcrumb"

import { Container, Col, Row } from "react-bootstrap"
import { graphql } from "gatsby"

import "src/assets/less/projects.less"

const ProgrammingProjects = ({ data }) => {

  const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a deveoloper"
  const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''

  return (
    <Layout page="projects">
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
      <Container>
        <Row>
          <Col xs={12} lg={8} sm={10}>
            <Breadcrumb />
            <div>These are my projects. You can sort by commercial/personal or technology used.</div>
            <ProjectsSorter projects={ data.allWpProject.edges }/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ProgrammingProjects


//cG9zdDo4Nw== is the id for the homepage... at least on dev..
export const pageQuery = graphql`
  query wpProgrammingProjectsQuery {
    allWpProject {
      edges {
        node {
          uri
          id
          title
          content
          slug
          metadata {
            metadataTitle
            metadataDescription
            metadataImage {
              localFile {
                url
              }
            }
          }
          project {
            description
            link
            techList {
              tech
            }
            year
            type
          }
        }
      }
    }
    wpPage(title: {eq: "PROGRAMMING PROJECTS"}) {
      metadata {
        metadataTitle
        metadataImage {
          localFile {
            url
          }
        }
        metadataDescription
      }
      title
      content
    }
  }`
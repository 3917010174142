import React, { useState, useEffect } from "react";
import { useLocation, navigate } from "@reach/router";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import MoodGraph from "src/components/graphs/MoodGraph";
import { Container, Col, Row } from "react-bootstrap";
import Footer from "src/components/Footer"
import { graphql } from "gatsby"

const MoodPage = ({ data }) => {
  const moodData = data.allMood.edges

  const [isPortrait, setIsPortrait] = useState(false);
  const [showMessage, setShowMessage] = useState(isPortrait);
  const [graphHeight, setGraphHeight] = useState('600px');

  useEffect(() => {
    const handleResize = () => {
      const portrait = window.innerWidth < window.innerHeight;
      setIsPortrait(prevIsPortrait => {
        if (prevIsPortrait !== portrait) {
          setShowMessage(portrait);
          setGraphHeight(portrait ? '600px' : '450px');
        }
        return portrait;
      });
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
  return (
    <Layout>
      <SEO title={`Adlai Abdelrazaq's Mood`} />
      <Container>
        <Row>
            <Col xs={12}>
                <Breadcrumb />
            </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {showMessage && (
              <div style={{ marginBottom: '10px', color: 'red' }}>
                This graph is more legible in landscape mode, try switching to it.
              </div>
            )}
            <MoodGraph data={moodData} height={graphHeight} />
          </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <p className="mt-10">
                  I try to update this tool every day with my mood. I update it by assigning a value 0-100 to my mood. 0 bad 100 good.
                </p>
                <p>
                  Plotting each of these mood points against time renders an image of your emotions over time. This reveals trends and provide accurate historical context into your emotional state at a glance.
                </p>
                <p>
                  The difficulty of assigning an number to your mood is intentional. 
                  By presenting mood -- which is universally perceived as subjective -- via the objective (a number value), the space between subjective and objective becomes exaggerated.
                  The result is uncanny - my emotional state is communicated through a graph's peaks and valleys on two axes.
                  This method aims to raise questions for the audience - how are these values quantified? Am I a reliable narrator? 
                  How else does false objectivity impact the stories we tell ourselves and others, and how does that frame our perspective?
                </p>
                <hr />
                <p>
                  Over time, the unique identifiers that that those in power have used to define us, the people, have become increasingly obfuscated from human recognition. <span style={{textDecoration: `underline`}}>Names</span> mean something to 
                  us, <span style={{textDecoration: `underline`}}>email addresses</span> might mean less. <a target="_blank" href="https://en.wikipedia.org/wiki/Universally_unique_identifier">uuids</a> and more recently layers in an LLM, give more insight into a person and their person-hood to a tech company. This project presents a lower-tech, higher accuracy model of displaying an <a href="https://en.wikipedia.org/wiki/Semiotics" target="_blank">icon</a> of a human with data.
                </p>
                <p>
                  Further -- despite the data being stored and distributed through communication streams between computer systems (a frontend, an API, a database) the meaning that the data creates stays obfuscated from the computer. For example - what do you think "feeling 56/100" means to a computer, versus what does it mean to <i>you</i>? 
                  Meaning can only be derived from this graph using empathy. <b>Empathy</b> is how we control the computer systems - instead of being controlled by them.
                </p>
                <p>
                  This project archives my mood and lets my friends know how I'm doing.
                </p>
            </Col>
        </Row>
      </Container>
      <Footer />
    </Layout>
  );
};

export default MoodPage;

export const pageQuery = graphql`
    query moodQuery {
      allMood {
        edges {
          node {
            mood
            createdAt
          }
        }
      }
    }
    
`
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const StepEnergyMoodGraph = ({ stepData, energyData, moodData, height }) => {
  const graphData = {
    labels: stepData.map(entry => entry.node.date),
    datasets: [
      {
        label: 'Steps',
        data: stepData.map(entry => ({ x: entry.node.date, y: entry.node.value })),
        fill: false,
        borderColor: 'rgba(0, 0, 0, 0.2)',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        tension: 0.1,
        yAxisID: 'y',
      },
      {
        label: 'Energy Burned (% of 1500 kcal)',
        data: energyData.map(entry => ({ x: entry.node.date, y: (entry.node.value / 1500) * 100 })), 
        fill: false,
        borderColor: 'rgba(255, 165, 0, 0.6)',
        backgroundColor: 'rgba(255, 165, 0, 0.1)',
        tension: 0.1,
        yAxisID: 'y1',
        hidden: true
      },
      {
        label: 'Mood',
        data: moodData.map(entry => ({ x: entry.node.createdAt, y: entry.node.mood })),
        fill: false,
        borderColor: 'rgba(75, 192, 192, 0.6)',
        backgroundColor: 'rgba(75, 192, 192, 0.1)',
        tension: 0.1,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    animation: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Steps',
        },
        position: 'left',
      },
      y1: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Calories Burned & Mood',
        },
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Steps, Calories Burned, and Mood Over Time',
      },
    },
  };

  const wrapperStyle = {
    height: height,
  };

  return (
    <div>
      <Line data={graphData} options={options} style={wrapperStyle} />
    </div>
  );
};

export default StepEnergyMoodGraph;

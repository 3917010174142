import React, { useState } from "react"
import { Container, Col, Row } from "react-bootstrap"
// import { useDispatch } from 'react-redux'

export default ({project, commercialSelected, nonCommercialSelected}) => {
  const [isShort, setIsShort] = useState(true)

  const toggleLength = (e) => {
    e.preventDefault()
    setIsShort(!isShort)
  }
  
  return (
    <div className="project">
      <div>
        <div className={`project-title`}>
          <h4>{project.title}</h4>
          <a href={project.link} className="link" target="_blank">
            Visit Project
          </a>
        </div>
        <div className="list">
          <div>{`${project.type} project`}</div>
        </div>
        <div className="list">
          {project.techList.map((techItem, index) => (
            <div key={index}>{techItem.tech}</div>)
          )}
        </div>
      </div>
      <div>
        <div className="description">
          {isShort ? 
          (<div><div dangerouslySetInnerHTML={{__html: `${project.description.substr(0, 100)}...`}}></div><a href="#" onClick={ toggleLength }>Read more</a></div>) : 
          (<div><div dangerouslySetInnerHTML={{__html: project.description}}></div><a href="#" onClick={ toggleLength }>Read less</a></div>)
          }
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { Link } from "gatsby"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import { Container, Col, Row } from "react-bootstrap"


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container><Row>
      <Col xs={12}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist. <Link to="/">Home.</Link></p>
      </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage

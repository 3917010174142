import React, { useEffect, useState, useRef } from "react"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Breadcrumb from "src/components/Breadcrumb"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"

import "src/assets/less/homepage.less"

const Cv = ({ data }) => {
  const [micePos, setMicePos] = useState({})
  const [coords, setCoords] = useState([])
  const [pathWidth, setPathWidth] = useState(-1)
  const [bodyHeight, setBodyHeight] = useState(0)
  const [headerRect, setHeaderRect] = useState(null)

  const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a deveoloper"
  const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''

  const skills = data.wpPage.cv.skills
  const experience = data.wpPage.cv.experience
  const description = data.wpPage.cv.description

  return (
    <Layout page="cv">
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
      <Container>
        <Row>
          <Col xs={12} lg={8} sm={10}>
            <Breadcrumb />
            <div className={`border-bottom`} dangerouslySetInnerHTML={{__html: description}}></div>
            <div className={`border-bottom`}>
                <p></p>
                <h3>Skills</h3>
                {skills.map((item, index) => {
                    return <p key={ index }>{ item.title }: { item.value }</p>
                })}
                <p></p>
            </div>
            <div className={`border-bottom`}>
                <p></p>
                <h3>Experience</h3>
                <ul>
                {experience.reverse().map((item, index) => {
                    return (
                    <li key={ index }>
                        <div style={{ display: `flex` }}>
                            <div style={{ marginRight: 5, fontWeight: `bold` }}>{item.company} -</div>
                            <div>{item.dates}</div>
                        </div>
                        <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{__html: item.description}}>
                        </div>
                    </li>)
                })}
                </ul>
                <p></p>
            </div>
            <div className={`border-bottom`}>
                <p></p>
                <h3>Projects</h3>
                <p>I have a list of programming projects I've worked on <Link to={`/programming/projects`}>here</Link>.</p>
                <p></p>
            </div>
            <div style={{marginBottom: 40}} className={`border-bottom`}>
                <p></p>
                <h3>Education</h3>
                <p>DePaul University - June 2019 <br />
                B.S. in Computer Science, minor in Mathematics</p>
                <p></p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Cv


export const pageQuery = graphql`
    query wpCvQuery {
        wpPage(title: {eq: "CV"}) {
        metadata {
            metadataTitle
            metadataImage {
            localFile {
                url
            }
            }
            metadataDescription
        }
        cv {
            description
            experience {
            company
            dates
            fieldGroupName
            }
            skills {
            title
            value
            }
        }
        }
    }
`
import React from 'react';
import { Link } from 'gatsby';

const MonthGrid = ({ year }) => {
  const months = [
    { name: 'January', abbr: 'jan' },
    { name: 'February', abbr: 'feb' },
    { name: 'March', abbr: 'mar' },
    { name: 'April', abbr: 'apr' },
    { name: 'May', abbr: 'may' },
    { name: 'June', abbr: 'jun' },
    { name: 'July', abbr: 'jul' },
    { name: 'August', abbr: 'aug' },
    { name: 'September', abbr: 'sep' },
    { name: 'October', abbr: 'oct' },
    { name: 'November', abbr: 'nov' },
    { name: 'December', abbr: 'dec' },
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-indexed
  const earliestMonth = 3; // March for 2020

  return (
    <div className="grid grid-cols-3 sm:grid-cols-6 gap-px black">
      {months.map((month, index) => {
        const monthNumber = index + 1;
        const isAvailable = (year === '2020' && monthNumber >= earliestMonth) ||
                            (year === `${currentYear}` && monthNumber <= currentMonth) ||
                            (year !== '2020' && year !== `${currentYear}`);
        const borderColor = isAvailable ? "border-black" : "border-gray-200";
        return (
          <div key={month.abbr} className={`py-4 border ${borderColor}`}>
            {isAvailable ? (
              <Link to={`/archive/${month.abbr}-${year}`} className="block text-center">
                {month.name}
              </Link>
            ) : (
              <div className="text-center text-gray-500">
                
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MonthGrid;

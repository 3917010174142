import React, { useRef, useEffect } from "react"
import "src/assets/less/mice.less"

const Mice = (props) => {
  const svg = useRef()
  const fuse = useRef()


  useEffect(() => {
    const fuseObj = fuse.current
    let length = fuseObj.getTotalLength()
    let coordinates = []

    const numSegments = 100

    for(let i = 0; i < numSegments; i ++) {
      let percent = i  * length / numSegments
      coordinates.push(fuseObj.getPointAtLength(percent))
    }
    
    props.setCoords(coordinates)
    props.setPathWidth(coordinates[numSegments - 1].x)
  }, [])


    return (
      <div className={`mice`}>
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
             viewBox="0 0 595.28 841.89" ref={svg} style={{fill: `none`, stroke: `#transparent`}}>
          <path className="st0" d="M0,50c16.65-19.77,25.22-24.88,59.43-42.91c33.54-16.91,35.29,1.49-1.31,44.23 c-8.35,13.23-34.86,40.62-34.09,46.75c-8.73,22.04,52.75-10.88,96.55-10.88c6.23,0,27.23-1.18,29.82,6.57 c0,4.56-7.58,10.21-5.41,14.54c16.68,37.34,120.08-57.65,138.45-36.04" ref={fuse} />
        </svg>
      </div>
    )
}

export default Mice
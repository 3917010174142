import React from "react";
import { components } from 'react-select';

const CustomDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      ▼ 
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
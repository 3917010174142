import React, { useEffect, useState, useRef } from "react"
import { Container, Col, Row } from "react-bootstrap"
import Breadcrumb from "src/components/Breadcrumb"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import StepEnergyMoodGraph from "src/components/graphs/StepEnergyMoodGraph"
import { graphql } from "gatsby"

import "src/assets/less/homepage.less"


const Activity = ({ data }) => {
  const [isPortrait, setIsPortrait] = useState(false);
  const [showMessage, setShowMessage] = useState(isPortrait);
  const [graphHeight, setGraphHeight] = useState('600px');

  useEffect(() => {
    const handleResize = () => {
      const portrait = window.innerWidth < window.innerHeight;
      setIsPortrait(prevIsPortrait => {
        if (prevIsPortrait !== portrait) {
          setShowMessage(portrait);
          setGraphHeight(portrait ? '600px' : '450px');
        }
        return portrait;
      });
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a deveoloper"
  const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''
  return (
    <Layout page="time" >
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
       <Container>
          <Row>
              <Col xs={12}>
                  <Breadcrumb />
                  <h1>Activity</h1>
                  <div dangerouslySetInnerHTML={{__html: data.wpPage.content}}></div>
                  { showMessage && (
                    <div style={{ marginBottom: '10px', color: 'red' }}>
                        This graph is more legible in landscape mode, try switching to it.
                    </div>
                  ) }
                  <StepEnergyMoodGraph 
                    stepData={data.allStep.edges} 
                    energyData={data.allEnergy.edges}
                    moodData={data.allMood.edges}
                    height={graphHeight} 
                  />
              </Col>
          </Row>    
      </Container>
    </Layout>
  )
}

export default Activity


export const pageQuery = graphql`
query ActivityQuery {
    wpPage(title: {eq: "ACTIVITY"}) {
        metadata {
          metadataTitle
          metadataImage {
            localFile {
              url
            }
          }
          metadataDescription
        }
        title
        content
      }
  
      allStep {
        edges {
          node {
            value
            date
          }
        }
      }
      allEnergy {
        edges {
           node {
             value
             date
           }
         }
       }
      allMood {
        edges {
            node {
              mood
              createdAt
            }
        }
      }
  }
  `
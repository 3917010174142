import React, { useState, useEffect, useMemo } from "react"
import { useLocation, navigate, Link } from "@reach/router";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Footer from "src/components/Footer";
import Breadcrumb from "src/components/Breadcrumb";
import ImageGallery from "src/components/images/ImageGallery";
import Pagination from "src/components/Pagination";
import { Container, Col, Row } from "react-bootstrap";
import { graphql } from "gatsby";
import axios from 'axios';

const Gallery = ({ data }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(searchParams.get('page'), 10) || 1;
  const yearFromUrl = searchParams.get('year');
  const monthFromUrl = searchParams.get('month');

  const monthMap = {
    'jan': 1,
    'feb': 2,
    'mar': 3,
    'apr': 4,
    'may': 5,
    'jun': 6,
    'jul': 7,
    'aug': 8,
    'sep': 9,
    'oct': 10,
    'nov': 11,
    'dec': 12
  };

  const imagesPerPage = 10; // Set to your desired number of images per page
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [page, setPage] = useState(pageFromUrl);

  const getImages = async (currentPage) => {
    let apiUrl = `${process.env.GATSBY_API_URL}image/?pagesize=${imagesPerPage}&page=${currentPage}`;
    if (yearFromUrl) {
      apiUrl += `&year=${yearFromUrl}`;
    }
    if (monthFromUrl && monthMap[monthFromUrl.toLowerCase()]) {
      const monthNumber = monthMap[monthFromUrl.toLowerCase()];
      apiUrl += `&month=${monthNumber}`;
    }

    try {
      setIsLoading(true);
      const response = await axios.get(apiUrl);
      const rawImages = response.data.items;
      const rest = response.data;
      const images = rawImages.map(item => ({ node: { hash: item.hash, createdAt: item.createdAt }}));
      const fullImageObject = { items: images, totalItems: rest.totalItems, totalPages: rest.totalPages, currentPage: rest.currentPage };
      setImageData(fullImageObject);
    } catch (error) {
      console.error("Failed to fetch images:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getImages(page);
    setPage(pageFromUrl);
  }, [pageFromUrl, page]);

  const handlePageChange = (newPage) => {
    window.location.href = `/images/?page=${newPage}`;
    window.scrollTo(0, 0);
  };

  const generateTitle = () => {
    let title = `Images`;
    if (yearFromUrl) {
      if (monthFromUrl) {
        title += ` - <span style="font-style: italic">${monthFromUrl[0].toUpperCase() + monthFromUrl.slice(1)} ${yearFromUrl}</span>`;
      } else {
        title += ` - <span style="font-style: italic">${yearFromUrl}</span>`;
      }
    }
    return title;
  };

  const metadataTitle = "Images";
  const metadataDescription = "All of my motha fuckin pics";
  const metadataImg = ""; // Set this if you have a specific image for SEO

  return (
    <Layout>
      <SEO title={metadataTitle} description={metadataDescription} imageURL={metadataImg} />
      <Container>
        <Row>
            <Col xs={12}>
              <Breadcrumb />
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <h1 className="mb-4" dangerouslySetInnerHTML={{ __html: generateTitle() }}></h1>
                  {
                    yearFromUrl ? (
                      <div>
                        These are the images that were uploaded during 
                        {monthFromUrl ? 
                          <span>
                          &nbsp;{monthFromUrl[0].toUpperCase() + monthFromUrl.slice(1)}&nbsp;
                          <Link to={`/images?year=${yearFromUrl}`}>{yearFromUrl}</Link>
                          </span> 
                        : 
                          <span>&nbsp;{yearFromUrl}</span>
                        }. View all the images <Link to="/images">here</Link>.
                      </div>
                    ) : (
                      <div dangerouslySetInnerHTML={{__html: data.wpPage?.content}} />
                    )
                  }
                  <hr />
                  <ImageGallery data={imageData.items} />
                </>
              )}
            </Col>
        </Row>
        <Row>
          {!isLoading && (
            <Col xs={12} className="text-center">
              <Pagination 
                  currentPage={imageData.currentPage} 
                  totalPages={imageData.totalPages} 
                  onPageChange={handlePageChange}
              />
            </Col>
          )}
        </Row>
      </Container>
      <Footer />
    </Layout>
  );
};

export default Gallery;

export const query = graphql`
    query ImageQuery {
      wpPage(title: {eq: "IMAGES"}) {
        metadata {
          metadataTitle
          metadataImage {
            localFile {
              url
            }
          }
          metadataDescription
        }
        title
        content
      }
    }
`;

import React, { useEffect, useState, useRef } from "react"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Body from "src/components/Body"
import Path from "src/components/Path"
import Paper from "src/components/Paper"
import { graphql } from "gatsby"

import "../assets/less/homepage.less"

const Homepage = ({ data }) => {
  const [micePos, setMicePos] = useState({})
  const [coords, setCoords] = useState([])
  const [pathWidth, setPathWidth] = useState(-1)
  const [bodyHeight, setBodyHeight] = useState(0)
  const [headerRect, setHeaderRect] = useState(null)


  const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a deveoloper"
  const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''
  return (
    <Layout page="homepage" setHeaderRect={setHeaderRect}>
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
      <Body content={data.wpPage.content} 
            setBodyHeight = { (height) => setBodyHeight(height) }
            headerRect={headerRect} />
      <Path pos={ micePos } setCoords={ setCoords } setPathWidth={setPathWidth} />
      <Paper pathCoords={ coords } 
             pathWidth={pathWidth} 
             bodyHeight={ bodyHeight }
              />
    </Layout>
  )
}

export default Homepage


export const pageQuery = graphql`
  query wpHomepageQuery {
    wpPage(title: {eq: "HOMEPAGE"}) {
      metadata {
        metadataTitle
        metadataImage {
          localFile {
            url
          }
        }
        metadataDescription
      }
      title
      content
    }
  }`
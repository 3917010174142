import React from "react"
import Breadcrumb from "src/components/Breadcrumb"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import BlogList from "src/components/blog/BlogList"
import Footer from "src/components/Footer"

import { Container, Col, Row } from "react-bootstrap"
import { graphql } from "gatsby"

import "src/assets/less/blog.less"

const Blog = ({ data }) => {
  const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a developer"
  const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''


  return (
    <Layout page="blog">
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
      
      <Container>
        <Row>
          <Col xs={12}>
            <Breadcrumb />
          </Col> 
          <Col xs={12}>
            <div className={`border-bottom`} dangerouslySetInnerHTML={{__html: data.wpPage.content}}></div>
            <BlogList blogs={data.allWpBlog.edges} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </Layout>
  )
}

export default Blog


//cG9zdDo4Nw== is the id for the homepage... at least on dev..
export const pageQuery = graphql`
  query MyBlogQuery {
    allWpBlog {
      edges {
        node {
          title
          metadata {
            metadataDescription
          }
          slug
          date
        }
      }
    }
    wpPage(title: {eq: "BLOG"}) {
      metadata {
        metadataTitle
        metadataImage {
          localFile {
            url
          }
        }
        metadataDescription
      }
      title
      content
    }
  }
`
import React from "react"
import Footer from "src/components/Footer"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Breadcrumb from "src/components/Breadcrumb"
import { Link, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ToggleAllColorGraph from "src/components/graphs/ToggleAllColorGraph"

const mergeColors = (rgbColors, imageColors) => {
    return rgbColors.map(rgbColor => {
        const matchedColor = imageColors.find(imageColor => 
            imageColor.rgbColor.name === rgbColor.name) 
        
        return matchedColor
        ? { ...rgbColor, imageCount: matchedColor.imageCount, images: matchedColor.images }
        : { ...rgbColor, imageCount: 0, images: [] }
    });
}

const generateColorBlocks = (colors) => {
    // Sort colors by luminance to create a dark-to-light order
    return colors.sort((a, b) => {
      const getLuminance = (hex) => {
        const r = parseInt(hex.slice(1, 3), 16)
        const g = parseInt(hex.slice(3, 5), 16)
        const b = parseInt(hex.slice(5, 7), 16)
        return (0.299 * r + 0.587 * g + 0.114 * b)
      }
      return getLuminance(a.hex) - getLuminance(b.hex)
    })
  }

const ImageColor = ({ data }) => {
    const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Image Colors"
    const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "Every image has a color"
    const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''

    const rgbColors = data.allRgbColor.edges.map(item => item.node)
    const imageColors = data.allImageColor.edges.map(item => item.node)
    const moodData = data.allMood.edges.map(item => item.node)

    const mergedColors = mergeColors(rgbColors, imageColors)

    const sortedColors = generateColorBlocks(mergedColors)

    const groupedColors = []
    for (let i = 0; i < sortedColors.length; i += 4) {
        groupedColors.push(sortedColors.slice(i, i + 4))
    }

    const getTextColorClass = (hex) => {
        const r = parseInt(hex.slice(1, 3), 16)
        const g = parseInt(hex.slice(3, 5), 16)
        const b = parseInt(hex.slice(5, 7), 16)
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255
        return luminance >= 0.5 ? 'text-black' : 'text-white'
    }


  return (
    <Layout>
      <SEO title={metadataTitle} description={metadataDescription} imageURL={metadataImg} />
      <Container>
        <Row>
          <Col xs={12}>
            <Breadcrumb />
            <ToggleAllColorGraph moodData={moodData} colorData={imageColors}/>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 p-4">
              {groupedColors.map((group, groupIndex) => (
                <div key={groupIndex} className="grid grid-cols-4 gap-0">
                  {group.map((item, colorIndex) => (
                    <Link
                      key={colorIndex}
                      to={`/image-color/${item.name}`}
                      className="w-full h-40 flex justify-center items-center p-4"
                      style={
                        { 
                            backgroundColor: item.hex, 
                        }
                      }
                    >
                        <span className={`text-center ${getTextColorClass(item.hex)}`}>
                        { item.name }
                        <br />
                        ( { item.imageCount} )
                        </span>
                      
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Layout>
  )
}

export default ImageColor

export const query = graphql`
    query ImageQuery {
      wpPage(title: {eq: "IMAGE-COLOR"}) {
        metadata {
          metadataTitle
          metadataImage {
            localFile {
              url
            }
          }
          metadataDescription
        }
        title
        content
      }
      allRgbColor {
        edges {
          node {
            hex
            name
          }
        }
      }
      allImageColor {
        edges {
          node {
            images {
              createdAt
            }
            imageCount
            rgbColor {
              name
              hex
            }
          }
        }
      } 
      allMood {
        edges {
          node {
            mood
            createdAt
          }
        }
      }  
    }
`;

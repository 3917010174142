import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import CartoonsList from "src/components/cartoons/CartoonsList"
import CartoonModal from "src/components/cartoons/CartoonModal"
import Breadcrumb from "src/components/Breadcrumb"

import { Container, Col, Row } from "react-bootstrap"
import { useScrollRestoration, getSavedScrollPosition } from "gatsby"

// import { graphql } from "gatsby"

import "src/assets/less/cartoons.less"

const Cartoons = ( data ) => {
  const [cartoonPos, setCartoonPos] = useState([])
  const [activeToon, setActiveToon] = useState({ index: data.pageContext.allWpCartoons.findIndex(element => element.slug === data.slug), 
                                                 toon: data.pageContext.cartoon})
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const metadataTitle = `${data.pageContext.title} | Adlai Abdelrazaq's Cartoons`
  const metadataDescription = `A cartoon drawn by Adlai Abdelrazaq`
  const metadataImg = data.pageContext.cartoon.imagePng.sourceUrl

  const sortedCartoons = data.pageContext.allWpCartoons.sort((a, b) => {
    return new Date(b.node.date) - new Date(a.node.date);
  });

  const location = data.location
  let locToon
  let scrollHeight
  if(data.location.state) {
    locToon = data.location.state.cartoon
    scrollHeight = data.location.state.scrollHeight
  } else {
    locToon = null
    scrollHeight = 0
  }


  return (
    <Layout page="cartoons">
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
      <Container>
        <Row>
          <Col xs={12}>
            <div className={`border-bottom`}>
              <p>
                <Breadcrumb />
                These are original cartoons that I’ve drawn. You can see more original cartoons <a href="https://knife-city.com/image-uploader/tags/original%20cartoons/">here.</a>
              </p>
            </div>
            <CartoonsList cartoons={ sortedCartoons } 
                          modalToon={activeToon.toon} 
                          cartoonPos={cartoonPos}
                          setCartoonPos={setCartoonPos}
                          toggleModal={setModalIsOpen} />
            <CartoonModal cartoon={ activeToon.toon }
                          cartoonSlug={data.pageContext.slug}
                          allCartoons={ data.pageContext.allWpCartoons }
                          location={ locToon }
                          cartoonPos={cartoonPos}
                          scrollHeight={scrollHeight}
                          isOpen={modalIsOpen} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Cartoons
import React from "react"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Breadcrumb from "src/components/Breadcrumb"
import MoodImageFrequencyGraph from "src/components/graphs/MoodImageFrequencyGraph";
import MonthGrid from "src/components/archive/MonthGrid";
import { Container, Col, Row } from "react-bootstrap"
import { Link } from "gatsby";
import { graphql } from "gatsby"


const groupAndSortTags = (tags) => {
    // Create an object to count occurrences of each tag
    const tagCounts = tags.reduce((acc, tag) => {
        if (acc[tag.tagName]) {
            acc[tag.tagName]++;
        } else {
            acc[tag.tagName] = 1;
        }
        return acc;
    }, {});

    const sortedTags = Object.entries(tagCounts)
    .filter(([tagName, count]) => count > 1)
    .map(([tagName, count]) => ({
        tagName,
        count
    }));

    

    sortedTags.sort((a, b) => b.count - a.count);

    return sortedTags;
}

const slugToTitle = (slug) => {
    const map = {
        'jan': 'January', 'feb': 'February', 'mar': 'March', 'apr': 'April',
        'may': 'May', 'jun': 'June', 'jul': 'July', 'aug': 'August',
        'sep': 'September', 'oct': 'October', 'nov': 'November', 'dec': 'December'
    };
    const parts = slug.split('-');
    if (parts.length === 2) {
        const [month, year] = parts;
        return `${map[month]} ${year}`;
    } else {
        return slug;
    }
}

const calculateMonthUrls = (currentSlug) => {
    const monthMap = {
        'jan': 'January', 'feb': 'February', 'mar': 'March', 'apr': 'April',
        'may': 'May', 'jun': 'June', 'jul': 'July', 'aug': 'August',
        'sep': 'September', 'oct': 'October', 'nov': 'November', 'dec': 'December'
    };
    const monthNumbers = {
        'jan': 1, 'feb': 2, 'mar': 3, 'apr': 4, 'may': 5, 'jun': 6,
        'jul': 7, 'aug': 8, 'sep': 9, 'oct': 10, 'nov': 11, 'dec': 12
    };

    const parts = currentSlug.split('-');
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // JavaScript months are 0-indexed

    if (parts.length === 2) {
        // Month-Year navigation
        const [month, year] = parts;
        const monthNum = monthNumbers[month];
        const yearNum = parseInt(year, 10);

        const previousYear = monthNum === 1 ? yearNum - 1 : yearNum;
        const nextYear = monthNum === 12 ? yearNum + 1 : yearNum;

        const previousMonth = monthNum === 1 ? 'dec' : Object.keys(monthNumbers).find(key => monthNumbers[key] === monthNum - 1);
        const nextMonth = monthNum === 12 ? 'jan' : Object.keys(monthNumbers).find(key => monthNumbers[key] === monthNum + 1);

        const previousMonthSlug = `${previousMonth}-${previousYear}`;
        const nextMonthSlug = `${nextMonth}-${nextYear}`;

        return {
            previousMonthUrl: yearNum > 2020 || (yearNum === 2020 && monthNum > 1) ? `/archive/${previousMonthSlug}` : null,
            nextMonthUrl: yearNum < currentYear || (yearNum === currentYear && monthNum < currentMonth) ? `/archive/${nextMonthSlug}` : null,
            previousMonthName: yearNum > 2020 || (yearNum === 2020 && monthNum > 1) ? `${monthMap[previousMonth]} ${previousYear}` : null,
            nextMonthName: yearNum < currentYear || (yearNum === currentYear && monthNum < currentMonth) ? `${monthMap[nextMonth]} ${nextYear}` : null
        };
    } else {
        // Year-only navigation
        const yearNum = parseInt(currentSlug, 10);

        return {
            previousMonthUrl: yearNum > 2020 ? `/archive/${yearNum - 1}` : null,
            nextMonthUrl: yearNum < currentYear ? `/archive/${yearNum + 1}` : null,
            previousMonthName: yearNum > 2020 ? `${yearNum - 1}` : null,
            nextMonthName: yearNum < currentYear ? `${yearNum + 1}` : null
        };
    }
};


const SeptemberTwennyFourUpdate = ({ data }) => {
  const imageData = data.allImage.edges.map(edge => edge.node);
  const moodData = data.allMood.edges.map(edge => edge.node);
  const tags = data.allTag.edges.map(edge => edge.node);


  const groupedTags = groupAndSortTags(tags)

  
  const metadataTitle = "September 2024 Update | Adlai Abdelrazaq"
  const metadataDescription = "September"
  const metadataImg = "https://adlai.pw/secretadmindir/wordpress/wp-content/uploads/2024/09/c_IMG_3648-scaled.jpg"
  return (
    <Layout page="blog">
      <SEO title={ metadataTitle }
           description={ metadataDescription }
           imageURL={ metadataImg } />
      <Container>
        <Row>
          <Col xs={12} lg={8} sm={10}>
            
            <div className={`blog-detail`}>
              <Breadcrumb />
              <h1>September 2024 Update</h1>
              <div>
                <p>Hello - I haven't done an update since July 1. Going forward I'm not going to be sending these out every month, because some months I may not be up to shit and don't want to send out an update just because. July was slow for me, but now I have an update.</p>
                <p>I moved to San Francisco! My living situation in LA wasn't working so I needed to find a new place - I had been questioning what I was doing in LA, and before I moved to LA I was starting to think about moving to Oakland CA. When I started dating Molly we pretty quickly decided that we would move to LA so that plan went out the window. We stopped dating and now I had the chance to move to the Bay so I took it!</p>
                <img src="https://gasfried.com/mothafuckin-api/image/fuck/c_IMG_3648.jpg" />
                <p>This is my apartment it's right in the Mission!</p>
                <p>I sold my <a href="https://abdelrazaq.com/images/tags/whip" target="_blank">whip</a> and now only have my skateboard as transportation. Going to be getting a bike.. soon..</p>
                <p>I've been drawing a lot and drawing in a new style as well. Here are some recent drawings:</p>
                <img src="https://gasfried.com/mothafuckin-api/image/fuck/c_IMG_3353.jpg" />
                <img src="https://gasfried.com/mothafuckin-api/image/fuck/c_IMG_3452.jpg" />
                <img src="https://gasfried.com/mothafuckin-api/image/fuck/c_IMG_3503.jpg" />
                <p>I also dyed my hair neon green</p>
                <img src="https://gasfried.com/mothafuckin-api/image/fuck/c_IMG_3528.jpg" /> 
                <p>I've wondered if my friends think I'm going crazy and I've wondered personally if I'm going crazy since I made a bunch of big decisions. But it also makes me question - what is a "big" decision?</p>
                <p>As I was leaving LA I made it a point to go to the beach as often as possible. I went swimming in the ocean 6 times in August.</p>
                <img src="https://gasfried.com/mothafuckin-api/image/fuck/c_IMG_3410.jpg" />
                <p>I'm going to miss LA a lot but in the year and a half that I spent there, there was nothing that made me say "wow I want to stay here forever." One day I'll end up with a little house in Chicago to call my own, but until then I want to stay out and keep experiencing new things - hence SF.</p>
                <p>When I told my friends that I was moving the response was mostly confusion or apathy which I don't blame them for.</p>
                <p>LA is so fucking cool I love DTLA ktown westside I love the freaks scammers hustlers that are in LA, but it was never really the place for me.</p>
                <p><a href="https://abdelrazaq.com/blog/leaving-la" target="_blank">This</a> is a blog post I wrote chronicling the last couple days I was in LA.</p>
                <br />
                <br />
                <p>Onward!</p>
              </div>
              <p>
                <h3>Mood and Image Upload Frequency</h3>
                <div>Number of days I tracked my mood: <b>{moodData.length}</b></div>
                <div>Average mood: <b>
                    {
                        moodData.length > 0
                          ? Math.round(moodData.reduce((acc, item) => acc + item.mood, 0) / moodData.length)
                          : "No data"
                      }
                </b></div>
                <div>Total images uploaded: <b>{imageData.length}</b></div>
                <MoodImageFrequencyGraph imageData={ imageData } moodData={ moodData } />
              </p>
              <p>
                <h3>Common Image Tags</h3>
                <ul>
                    {groupedTags.map((item, index) => {
                      const url = `/images/tags/${item.tagName}`;
                      return (
                        <li>
                            <Link  href={ url ? url : `nothing..` } key={index}>
                                {item.tagName} ({item.count})
                            </Link>
                        </li>
                    )})}
                </ul>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SeptemberTwennyFourUpdate

export const query = graphql`
    query StuffQuery {
      allMood(filter: {createdAt: {gt: "2024-06-31"}}) {
        edges {
          node {
            mood
            createdAt
          }
        }
      }
      allTag(filter: {createdAt: {gt: "2024-06-31"}}) {
        edges {
          node {
            createdAt
            imageId
            tagName
          }
        }
      }
      allImage(filter: {createdAt: {gt: "2024-06-31"}}) {
        edges {
          node {
            hash
            id
            createdAt
          }
        }
      }
      
    }
    
`;

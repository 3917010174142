import React from "react";
import { components } from 'react-select';

const CustomRemoveIcon = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      ✕
    </components.MultiValueRemove>
  );
};

export default CustomRemoveIcon
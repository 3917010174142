import React, { useState, useEffect } from "react";
// import AsciiDrag from "src/components/games/ascii-drag";
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/Breadcrumb";
import { Container, Col, Row } from "react-bootstrap";
import Footer from "src/components/Footer"
import loadable from '@loadable/component';

import { computer, crane, cubes, earth, explosion, grimreaper, hoops } from "src/components/games/ascii-drag/ascii-files";


const AsciiDragPage = () => {
  const [lastLineHeight, setLastLineHeight] = useState(0);
  const [text, setText] = useState(grimreaper)
  const [selectedAscii, setSelectedAscii] = useState("Grim Reaper")
  const [instanceKey, setInstanceKey] = useState(0);

  const asciiOptions = {
    Computer: computer,
    Crane: crane,
    Cubes: cubes,
    Earth: earth,
    Explosion: explosion,
    'Grim Reaper': grimreaper,
    Hoops: hoops,
  };

    const handleHeightUpdate = (height) => {
        setLastLineHeight(height);
    };

    const handleAsciiChange = (event) => {
        const selectedAscii = event.target.value;
        setText(asciiOptions[selectedAscii]);
        setSelectedAscii(event.target.value)
        setInstanceKey((prevKey) => prevKey + 1);
    };

    const AsciiDrag = loadable(() => import('src/components/games/ascii-drag'));
    return (
        <Layout>
            <SEO title={`ASCII Drag`}
                 description={``} />
            <Container>
                <Row>
                    <Col xs={12}>
                        <Breadcrumb />
                        <h1>ASCII Drag</h1>
                        <p>Change the ASCII:</p>
                        <select onChange={handleAsciiChange} style={{ padding: 5, marginBottom: '20px' }} value={selectedAscii}>
                            {Object.keys(asciiOptions).map((key) => (
                                <option key={key} value={key}>
                                {key}
                                </option>
                            ))}
                        </select>
                        <p>Drag your finger / mouse over the letters below.</p>
                        <AsciiDrag key={instanceKey} text={text} handleHeightUpdate={handleHeightUpdate} />
                    </Col>
                </Row>    
            </Container>
            <Footer />
        </Layout>
    );
};

export default AsciiDragPage;